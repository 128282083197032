import React from 'react';
import {Table, Form} from "react-bootstrap";
import moment from "moment";
import "./CheckInTable.css"
import BootstrapTable from 'react-bootstrap-table-next';
import {useHistory} from "react-router-dom";

const CheckInTable = (props) => {
    const history = useHistory()
    const {checkinData,isShowOthers,selectedRowData,isLoadingEmploye} = props;
    if(checkinData.status){
        return null
    }
    const columns = [
        {
            dataField: 'accountnumber',
            text: 'Account #',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: (call) => {
                return { 'width': '8%' };
            },
            sort: true,
        },{
            dataField: 'fname',
            text: 'First Name',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },{
            dataField: 'lname',
            text: 'Last Name',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },{
            dataField: 'phone',
            text: 'Phone',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: false
        },{
            dataField: 'email',
            text: 'Email',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle:()=>{
                return {'width':'13%'}
            },
            sort: false,
        },{
            dataField: 'TimeZone',
            text: 'Time Zone',
            style: (cell, row,index,index2) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
                return { textAlign:'center'}
            },
            headerStyle: () => {
                return { 'width' : '5%','text-align':'center' };
            },
            sort: true
        },{
            dataField: 'lastcheckindate',
            text: 'Last Checked \nIn Date',
            formatter: (value, row) => (
                <span>{row.lastcheckindate && moment(row.lastcheckindate).format('MM/DD/YYYY')}</span>
            ),
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
                return {textAlign:'center'}
            },
            headerStyle: () => {
                return { 'width': '8%','white-space': 'break-spaces','text-align':'center'};
            },
            sort: false
        },{
            dataField: 'nextcheckindate',
            text: `Next Check \nIn Date`,
            formatter: (value, row) => (
                <span>{row.nextcheckindate && moment(row.nextcheckindate).format('MM/DD/YYYY')}</span>
            ),
            headerStyle: () => {
                return { 'width': '8%','white-space': 'break-spaces','text-align':'center'};
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF',
                    };
                }
            },
            align: 'center',
            sort: false
        },{
            dataField: 'HasCheckedInToday',
            text: 'Checked In',
            formatter: (value, row) => (
                <span>{row.HasCheckedInToday && moment(row.HasCheckedInToday).format('MM/DD/YYYY hh:mm A')}</span>
            ),
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return { 'width': '11%','text-align':'center' };
            },
            align: 'center',
            sort: false
        },{
            dataField: 'textsent1',
            text: 'Text #1',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.textsent1 !== null}
                    readOnly={true}
                />
            ),
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            align: "center",
            sort: false
        },{
            dataField: 'textsent2',
            text: 'Text #2',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.textsent2 !== null}
                    readOnly={true}
                />
            ),
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
             headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            align: "center",
            sort: false
        },{
            dataField: 'textsent3',
            text: 'Text #3',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.textsent3 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false
        },{
            dataField: 'textsent4',
            text: 'Text #4',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.textsent4 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false
        },{
            dataField: 'call1',
            text: 'Call #1',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.call1 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false
        },{
            dataField: 'call2',
            text: 'Call #2',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.call2 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%' ,'text-align':'center'};
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false,
        },{
            dataField: 'ice1',
            text: 'ICE #1',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.ice1 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%' ,'text-align':'center'};
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false
        },{
            dataField: 'ice2',
            text: 'ICE #2',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.ice2 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false
        },{
            dataField: 'ice3',
            text: 'ICE #3',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.ice3 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%' ,'text-align':'center'};
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false
        },
        {
            dataField: 'carry_over',
            text: 'Carry Over?',
            formatter: (value, row) => (
                <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    checked={row.ice3 !== null}
                    readOnly={true}
                />
            ),
             headerStyle: () => {
                return { 'width': '1%','text-align':'center' };
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false,
            hidden : !isShowOthers
        },
        {
            dataField: 'AssignedTo',
            text: 'Assigned To',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            align: "center",
            sort: false,
            headerStyle: () => {
                return { 'word-break': 'initial','text-align':'center' };
            },
            hidden :!isShowOthers,
        },
    ];
    const rowEvents = {
        onClick: (e, row) => {
            history.push(`/CheckinDetails/${row.RecordID}/${row.UserID}`)
        }
    }
    return (
        <BootstrapTable
            keyField="id"
            data={checkinData}
            columns={columns}
            hover={true}
            rowEvents={rowEvents}
        />
    );
};

export default CheckInTable;

import React, {useEffect, useRef, useState} from 'react';
import Header from "../../component/header/Header";
import {Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import Back from "../../../assets/images/Back_white.png";
import MaintainenceLogout from "../../../components/logout/MaintainenceLogout";
import axios from "axios";
import moment from "moment";
import ConfirmationModel from "../../../components/ConfirmationModel";
import "../Daily Reporting/Daily_Reporting.css"

const ChekinDetails = () => {
    const {recordID, userId} = useParams();
    const history = useHistory()
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);
    const notesInput = useRef();
    const date = new Date()
    const [showModel, setShowModel] = useState(false);
    const [emergencyContact, setEmergencyContact] = useState([]);
    const [checkinDetail, setCheckInDetails] = useState([]);
    const [confirmModel, setConfirmModel] = React.useState(false);
    const [contactType, setContactType] = useState(false);
    const [carryOverChecked, setCarryOverChecked] = useState(false);
    const [notes, setNotes] = useState('');

    const handleShowAddNoteModal = () => {
        setShowAddNoteModal(true);
    }
    useEffect(() => {
        getUserDetailApi();
    }, [])

    const handleCallCarryOver = (value) => {
        setCarryOverChecked(value)
        const data = {
            nUserID: localStorage.getItem('userid'),
            nRecordID: recordID,
            isCarryOver: value ? 1 : 0,
        }
        axios.post('/user/bodailyCarryOver', data)
        .then((response) => {
        }).catch((err) => {
            console.log(err)
        })
    }

    const getUserDetailApi = () => {
        axios.get(`user/bodailycheckinDetailsByUser?nUserID=${userId}&nRecordID=${recordID}`)
            .then((response) => {
                setCheckInDetails(response.data[0])
            }).catch((err) => {
            console.log(err)
        })
        axios.get(`user/bodailycheckinGetEmergContacts?nUserID=${userId}`)
            .then((response) => {
                setEmergencyContact(response.data)
            }).catch((err) => {
            console.log(err)
        });
    };
    const confirmationModel = (type) => {
        setConfirmModel(!confirmModel)
        setContactType(type)
    };
    const handleCloseModel = () => {
        setShowModel(false)
    }
    const HandleUserCheckinApproval = () => {
        const data = {
            nUserID: localStorage.getItem('userid'),
            nWhoCheckedIn: userId,
            nRecordID: recordID
        }
        axios.post(process.env.REACT_APP_SERVER + 'user/bodailycheckUserInBySystem', data)
            .then((res) => {
                getUserDetailApi();
                handleCloseModel();
            }).catch((err) => {
            console.log(err)
        })
    }
    const handlePhoneCall = () => {
        const data = {
            nUserID: userId,
            nRecordID: recordID,
            nSystemUserID: localStorage.getItem('userid'),
            sPhoneCall: contactType
        }
        return axios.post(`user/bodailycheckinCall`, data)
            .then((response) => {
                setConfirmModel(false)
            }).catch((err) => {
                setConfirmModel(false)
            })
    }
    const handleCloseAdNotesModal = () => {
        setShowAddNoteModal(false);
        setNotes('');
    }

    const handleAddNotes = () => {
        const token = localStorage.getItem('maintenance-token');
        const data = {
            nUserID: userId,
            nRecordID: recordID,
            sNotes: notes,
            isCarryOver: carryOverChecked ? 1 : 0,
        }
        axios.post(process.env.REACT_APP_SERVER + 'user/bodailycheckinNotes', data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            getUserDetailApi();
            handleCloseAdNotesModal();
        }).catch((err) => {
            console.log(err)
        });
    };

    const handleBack = () => {
        history.goBack()
    }
    return (
        <>
            <Header title="Checkin Details"/>
            <Row className="mx-auto mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <Button onClick={handleBack} className="btn custom-link btn-primary">
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </Button>
                </Col>
                <Col
                    lg={6} md={6} sm={6} xs={6} className="btnall"
                ><MaintainenceLogout/>
                </Col>
                <h4 className='w-100 mb-2 text-center'>{moment(date).format(('MMM DD, YYYY'))}</h4>
                <div className="d-flex flex-wrap w-100">
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Form className="edit_data_form">
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Account #:</Form.Label>
                                    <Form.Control type="text" defaultValue={checkinDetail.accountnumber}
                                                  readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control type="text" defaultValue={checkinDetail.UserName} readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Phone:</Form.Label>
                                    <Form.Control type="text" defaultValue={checkinDetail.phone} readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Last Checked In:</Form.Label>
                                    <Form.Control type="text"
                                                  defaultValue={moment(checkinDetail.lastcheckindate).format('MM/DD/YYYY')}
                                                  readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Next Check In:</Form.Label>
                                    <Form.Control type="text"
                                                  defaultValue={moment(checkinDetail.nextcheckindate).format('MM/DD/YYYY')}
                                                  readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Activation:</Form.Label>
                                    <Form.Control type="text" defaultValue={checkinDetail.created} readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control type="email" defaultValue={checkinDetail.email} readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-7 row-fields" controlId="formBasicEmail">
                                    <Form.Label>Plan:</Form.Label>
                                    <Form.Control type="text" defaultValue={checkinDetail.name} readOnly={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="col-md-11 d-flex textarea-field" controlId="formBasicEmail">
                                    <Form.Label className="form_label"><b>Today's Notes:</b></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        style={{height: '170px'}}
                                        value={checkinDetail.notes}
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Button className={'add-notes-btn'} onClick={handleShowAddNoteModal}>Add Notes</Button>
                        </Form>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Table responsive className="table_1">
                            <thead>
                            <tr>
                                <th colSpan={2}>
                                    <Form.Group controlId="addNotesCarryOverCheckbox"
                                                className="from-group-section active-status">
                                        <Form.Check type="checkbox" label="Carry Over"
                                                    className="Checked-box d-flex align-items-center"
                                                    checked={carryOverChecked}
                                                    onChange={(e) => handleCallCarryOver(e.target.checked)}/>
                                    </Form.Group>
                                </th>
                                <th/>
                                <th/>
                                <th>Performed By:</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Text #1</td>
                                <td>Text Sent At:</td>
                                <td>{checkinDetail.textsent1 ? moment(checkinDetail.textsent1).format('MM/DD/YYYY hh:mm A') : ''}</td>
                                <td/>
                                <td className={'performed-by'}>
                                    <span>{checkinDetail.TextUser1}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Text #2</td>
                                <td>Text Sent At:</td>
                                <td>{checkinDetail.textsent2 ? moment(checkinDetail.textsent2).format('MM/DD/YYYY hh:mm A') : ''}</td>
                                <td/>
                                <td className={'performed-by'}>
                                    <span>{checkinDetail.TextUser2}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Text #3</td>
                                <td>Text Sent At:</td>
                                <td>{checkinDetail.textsent2 ? moment(checkinDetail.textsent2).format('MM/DD/YYYY hh:mm A') : ''}</td>
                                <td/>
                                <td className={'performed-by'}>
                                    <span>{checkinDetail.TextUser2}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Text #4</td>
                                <td>Text Sent At:</td>
                                <td>{checkinDetail.textsent2 ? moment(checkinDetail.textsent2).format('MM/DD/YYYY hh:mm A') : ''}</td>
                                <td/>
                                <td className={'performed-by'}>
                                    <span>{checkinDetail.TextUser2}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Call #1</td>
                                <td><Button onClick={() => confirmationModel('c1')} variant="outline-dark">Phone
                                    Call</Button></td>
                                <td>{checkinDetail.call1 ? moment(checkinDetail.call1).format('MM/DD/YYYY hh:mm A') : null}</td>
                                <td/>
                                <td className={'performed-by'}>
                                    <span>{checkinDetail.CallUser1}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Call #2</td>
                                <td><Button onClick={() => confirmationModel('c2')} variant="outline-dark">Phone
                                    Call</Button></td>
                                <td>{checkinDetail.call2 ? moment(checkinDetail.call2).format('MM/DD/YYYY hh:mm A') : null}</td>
                                <td/>
                                <td className={'performed-by'}>
                                    <span>{checkinDetail.CallUser2}</span>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td/>
                                <td className="pt-3"><b>Emergency Contacts</b></td>
                                <td className="pt-3"><b>Phone #</b></td>
                                <td/>
                            </tr>
                            {emergencyContact.map((contact, index) =>
                                <tr>
                                    <td/>
                                    <td><Button onClick={() => confirmationModel('e' + (index + 1))}
                                                variant="outline-dark">Phone Call</Button></td>
                                    <td>#{index + 1} {contact.EmergencyContact}</td>
                                    <td>{contact.phonenumber}</td>
                                    <td className={'performed-by'}>
                                        <span>{checkinDetail['ICECall' + (index + 1)]}</span>
                                    </td>
                                </tr>
                            )}
                            <tr className={'h-25'}>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <Button variant="danger" className={'approval-btn'}
                                            onClick={() => setShowModel(true)}>User Checkin Approval</Button>
                                </td>
                                <td className={'align-middle'}>Reason For Us Approving?</td>
                                <td/>
                                <td className={'performed-by align-middle'}>
                                    <Form.Control
                                        type="text"
                                        defaultValue={checkinDetail.WhoCheckedInUser}
                                        readOnly={true}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </div>
            </Row>
            <Modal show={showModel} onHide={handleCloseModel}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title> Confirm Check In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to check this user in for the day?</Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleCloseModel}
                        style={{backgroundColor: "#9c9b9c", color: "white", border: 'none'}}
                    >
                        No
                    </Button>
                    <Button
                        onClick={HandleUserCheckinApproval}
                        style={{backgroundColor: "#7030a0", color: "white", border: 'none'}}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmationModel
                IsOpen={confirmModel}
                handleClose={confirmationModel}
                handlePhoneCall={handlePhoneCall}
                contactType={contactType}
                getUserDetailApi={getUserDetailApi}
            />
            <Modal show={showAddNoteModal} onHide={handleCloseAdNotesModal} className={"add-notes-modal"}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title> Add Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="add_note_form">
                        <Form.Group controlId="notes_textarea">
                            <Form.Label className="form_label"><b>Notes: </b></Form.Label>
                            <Form.Control
                                ref={notesInput}
                                as="textarea"
                                placeholder="Add note here"
                                style={{height: '190px'}}
                                value={notes}
                                onChange={e => setNotes(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleCloseAdNotesModal}
                        style={{backgroundColor: "#9c9b9c", color: "white", border: 'none'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddNotes}
                        style={{backgroundColor: "#7030a0", color: "white", border: 'none'}}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ChekinDetails;
import React, { useState } from 'react';
import Header from '../../../admin/component/header/Header';
import { Form, FormControl, FormGroup, Button, Alert, Table, Row, Col, Container, Card } from 'react-bootstrap';
import './subscribePlan.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import checkRight from '../../images/check-grey.svg'
import pricetable from '../../images/pricetable2.jpg'
import arrowRight from '../../images/arrow-right.svg'
import { Link,useHistory } from 'react-router-dom';
import SubscribePayment from './Payment/SubscribePayment'
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import Back from '../../../assets/images/Back_white.png'

const SubscribePlan = () => {
    let history = useHistory()
    const [PaymentOpen, setPaymentOpen] = useState(false)
    const [subscribePlan, setSubscribePlan] = useState('')

    const MonthlyPlan = [
        {
            id: 1,
            plan: "Basic",
            Price: "5.99",
            PlanTime: "month"

        }, {
            id: 2,
            plan: "Premium",
            Price: "15.99",
            PlanTime: "month"
        }, {
            id: 3,
            plan: "Basic",
            Price: "20.99",
            PlanTime: "Yearly"
        },
        {
            id: 4,
            plan: "Premium",
            Price: "25.99",
            PlanTime: "Yearly"
        },

    ]

    const monthlySelectPlan = (item) => {
        setSubscribePlan(item)
        setPaymentOpen(true)
    }
    function ClosePaymnetBtn() {
        setPaymentOpen(false)
    }
    
    const HandlingRoutes = () => {
        history.goBack()
    }
    return (
        <>
            <section className="subscribe-pan-section common-font-section">
                <div>
                    <Header title="Subscribe to a plan" />
                </div>
                {
                    PaymentOpen == false ?
                        <div>
                            <Row className="mx-auto text-center mobile-button back-logout-btn">
                                <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="p-2 text-start profile-left-btn d-flex btnall"
                                >
                                    <Button to="/maintenance-login" className="btn custom-link btn-primary" onClick={HandlingRoutes}>
                                        <img src={Back} alt="Back" className="w-5"/> Back
                                    </Button>
                                </Col>
                                <Col
                                    lg={6} md={6} sm={6} xs={6} className="btnall"
                                >
                                    <MaintainenceLogout/>
                                </Col>
                            </Row>

                            <div className="subscribe-inner-section">
                                <Container>
                                    <Tabs>
                                        <TabList>
                                            <Tab>Monthly</Tab>
                                            <Tab>Yearly</Tab>
                                        </TabList>

                                        <TabPanel>
                                            <div className="row">
                                                {
                                                    MonthlyPlan.map(item => (
                                                        item.PlanTime === 'month' &&
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="pricing-table purple mr-3">
                                                                <div className="d-flex pricing-title-header">
                                                                    <img src={pricetable} />
                                                                    <div className="price_amount_right">
                                                                        <h2>{item.plan}</h2>
                                                                        <div >
                                                                            <span className="symbol">$</span>
                                                                            <span className="amount">{item.Price}</span>
                                                                            <span className="after">/{item.PlanTime}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="pricing-content">
                                                                    <ul>
                                                                        <li className="flex  mb-2">
                                                                            <img src={checkRight} />
                                                                            <span className="ml-3">Pre Month</span>
                                                                        </li>
                                                                        <li className="flex  mb-2">
                                                                            <img src={checkRight} />
                                                                            <span className="ml-3">Billed Monthly</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <Link className="price-button" onClick={() => monthlySelectPlan(item)}>Selected <span className="ml-3"><img src={arrowRight} /></span></Link>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="row">
                                                {
                                                    MonthlyPlan.map(item => (
                                                        item.PlanTime === 'Yearly' &&
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="pricing-table purple mr-3">
                                                                <div className="d-flex pricing-title-header">
                                                                    <img src={pricetable} />
                                                                    <div className="price_amount_right">
                                                                        <h2>{item.plan}</h2>
                                                                        <div >
                                                                            <span className="symbol">$</span>
                                                                            <span className="amount">{item.Price}</span>
                                                                            <span className="after">/ {item.PlanTime}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="pricing-content">
                                                                    <ul>
                                                                        <li className="flex  mb-2">
                                                                            <img src={checkRight} />
                                                                            <span className="ml-3">Pre Year</span>
                                                                        </li>
                                                                        <li className="flex  mb-2">
                                                                            <img src={checkRight} />
                                                                            <span className="ml-3">Billed Yearly</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <Link className="price-button" onClick={() => monthlySelectPlan(item)}>Selected <span className="ml-3"><img src={arrowRight} /></span></Link>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </Container>
                            </div>
                        </div>
                        :
                        <div className="payment-section-data ">
                            <div >
                                <button onClick={() => ClosePaymnetBtn()}>Back</button>
                                <Container className="d-flex paymnet-popup">
                                    <div className="plan-details-section w-50  mt-4">
                                        <div >
                                            <h6 className="paymnet-title-detail">Payment Detail Plan</h6>
                                            <div className="paymnet-inner-content mt-3">
                                                <div className="d-flex paymnet-plan-data">
                                                    <h6>Plan Name :</h6>
                                                    <p> {subscribePlan.plan}</p>
                                                </div>
                                                <div className="d-flex paymnet-plan-data">
                                                    <h6>Plan Price :</h6>
                                                    <p> ${subscribePlan.Price}/{subscribePlan.PlanTime}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <SubscribePayment  planPrice={subscribePlan.Price}/>
                                </Container>
                            </div>
                        </div>
                }

            </section>
        </>
    )
}

export default SubscribePlan

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../header/Header';
import { Row, Col, Form, Button, } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { EditorState, ContentState, convertToRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import $ from "jquery";
import './pageCreation.css'
import Logout from '../../../components/logout/Logout';

const PageCreation = () => {
    const [dataID, setDataID] = useState('');
    const [editorState, setEditorData] = useState(EditorState.createEmpty())
    const [editorBodyState, setEditorBodyData] = useState(EditorState.createEmpty())
    const editorReference = useRef();
    const [deletebtnDisable, setDeletebtnDisable] = useState(false)
    const [pageTitle, setPageTitle] = useState('')
    const [activated, setActivated] = useState(0)
    const [selectValue, setSelectValue] = useState('')
    const [preview, setPreview] = useState(JSON.parse(localStorage.getItem("preview")))

    useEffect(() => {
        if(preview){
            setPageTitle(preview.HeadingText !== '' ? preview.pageTitle : '')
            setActivated(preview.Activated)
            if (preview.Activated === 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
            }
            setSelectValue(preview.type)
            const contentBlock = htmlToDraft(preview.HeadingText);
            const contentBlockTow = htmlToDraft(preview.BodyText);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorData(editorState)
            }
            if (contentBlockTow) {
                const contentState1 = ContentState.createFromBlockArray(contentBlockTow.contentBlocks);
                const editorBodyState = EditorState.createWithContent(contentState1);
                setEditorBodyData(editorBodyState)
            }
        }
    }, [])

    // editor
    let HeadingText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    let BodyText = draftToHtml(convertToRaw(editorBodyState.getCurrentContent()))


    //   table
    const products = [
        {
            id: "1",
            thequestion: "MySQL - finding Stored Procs, foreign keys, tables and columns",
            status: "Active",
            pagetitle: 'Terms of Use',
            Created: "5/16/21 1:24 AM",
            Updated: "5/16/21 1:24 AM",
        },
        {
            id: "2",
            thequestion: "WAP Choreographer JaQuel Knight - Copywrighting of Dance Move ",
            status: "Active",
            pagetitle: '',
            Created: "4/24/21 9:58 AM ",
            Updated: "4/24/21 9:58 AM",
        },
        {
            id: "3",
            thequestion: " MySQL Insert statement ",
            status: "Active",
            pagetitle: '',
            Created: "4/13/21 10:05 AM",
            Updated: "4/13/21 10:05 AM",
        },
        {
            id: "4",
            thequestion: "German Chocolate Cake Recipe  ",
            status: "Active",
            pagetitle: '',
            Created: "4/10/21 7:55 PM ",
            Updated: "4/10/21 7:55 PM",
        },
        {
            id: "5",
            thequestion: "Mysql encryption ",
            status: "Active",
            pagetitle: '',
            Created: "4/8/21 8:21 PM ",
            Updated: "4/8/21 8:21 PM",
        },
        {
            id: "6",
            thequestion: "Home Building  ",
            pagetitle: '',
            status: "Active",
            Created: "2/25/21 11:44 PM ",
            Updated: "2/25/21 11:44 PM",
        },
        {
            id: "7",
            thequestion: "Temp agencies have long been accused of discriminating against Black ",
            pagetitle: '',
            status: "Active",
            Created: "2/23/21 6:32 PM ",
            Updated: "2/23/21 6:32 PM",
        },
        {
            id: "8",
            thequestion: "ATT Internet Speed ",
            pagetitle: '',
            status: "Active",
            Created: "1/25/21 8:23 PM ",
            Updated: "1/25/21 8:23 PM",
        },
        {
            id: "9",
            thequestion: "Birth Certificate ",
            pagetitle: '',
            status: "Active",
            Created: "1/25/21 8:19 PM ",
            Updated: "1/25/21 8:19 PM",
        },
        {
            id: "10",
            thequestion: "MySQL - Group_Concat - multiple rows into 1 row ",
            pagetitle: '',
            status: "In Active",
            Created: "1/7/21 3:53 PM ",
            Updated: "1/7/21 3:53 PM",
        },
        {
            id: "11",
            thequestion: " MYSql - Get DBase Size and List of all Indexes Query",
            pagetitle: '',
            status: "In Active",
            Created: " 1/7/21 3:53 PM ",
            Updated: "1/7/21 3:53 PM",
        },
        {
            id: "12",
            thequestion: " Fighting Covid at Home ",
            pagetitle: '',
            status: "In Active",
            Created: "12/29/20 8:04 PM ",
            Updated: "12/29/20 8:04 PM "
        },
        {
            id: "13",
            thequestion: " Data Analyst ",
            pagetitle: '',
            status: "In Active",
            Created: "12/10/20 12:33 PM   ",
            Updated: "12/10/20 12:33 PM"
        },
        {
            id: "14",
            thequestion: "Tamika ",
            status: "In Active",
            Created: "11/19/20 10:30 AM ",
            pagetitle: '',
            Updated: "11/19/20 10:30 AM "
        },

        {
            id: "15",
            thequestion: "Unisys ",
            pagetitle: '',
            status: "In Active",
            Created: "11/18/20 12:40 PM ",
            Updated: "11/18/20 12:40 PM"
        },
        {
            id: "16",
            pagetitle: '',
            thequestion: " SQL Server Performance Tuning Tips ",
            status: "In Active",
            Created: " 10/26/20 7:10 PM ",
            Updated: "10/26/20 7:10 PM "
        },
    ];
    const columns = [
        {
            dataField: 'thequestion',
            text: 'Questions',
            formatter: (cell) => {
                if (cell) {
                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cell
                            }}
                        />
                    );
                }
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === dataID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        },
        {
            dataField: 'pagetitle',
            text: 'Page Title',
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === dataID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }, {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => {
                if (cell === "Active") {
                    return (
                        <span>
                            <strong style={{ color: '#28a745' }}> Active </strong>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            <strong style={{ color: '#dc3545' }}> In Active </strong>
                        </span>
                    );
                }
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === dataID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }, {
            dataField: 'Created',
            text: 'Created',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            sortValue: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell).getTime();
                }
                return dateObj;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === dataID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true

        }, {
            dataField: 'Updated',
            text: 'Updated',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            sortValue: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell).getTime();
                }
                return dateObj;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === dataID) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }
    ];
    // editor
    const onEditorStateChange = (editorState) => {
        setEditorData(editorState);
    };
    function onEditorState1Change(editorBodyState) {
        setEditorBodyData(editorBodyState);
    }
    // select type
    function selectTypeDropdown(e) {
        setSelectValue(e.target.value)
    }
    // add new button fun
    function newButtonClick() {
        setDeletebtnDisable(false)
        $(`#formBasicCheckbox`).prop("checked", false)
        setEditorData(EditorState.createEmpty())
        setEditorBodyData(EditorState.createEmpty())
    }
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            const contentBlock = htmlToDraft(row.thequestion);
            const contentBlockTow = htmlToDraft('');
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorData(editorState)
            }
            if (contentBlockTow) {
                const contentState1 = ContentState.createFromBlockArray(contentBlockTow.contentBlocks);
                const editorBodyState = EditorState.createWithContent(contentState1);
                setEditorBodyData(editorBodyState)
            }
            setPageTitle(row.pagetitle)
            setDeletebtnDisable(true)
            if (row.status === "Active") {
                $(`#formBasicCheckbox`).prop("checked", true)
                setActivated(1)

            } else {
                $(`#formBasicCheckbox`).prop("checked", false)
                setActivated(0)
            }
        }
    }
    // delete recode
    function deleteButtonClicked() {
        $(`#formBasicCheckbox`).prop("checked", false)
        setEditorData(EditorState.createEmpty())
        setEditorBodyData(EditorState.createEmpty())
        setDeletebtnDisable(false)
    }
    // checkbox value 
    function checkboxFun(e) {
        let target = e.target;
        if (target.checked) {
            setActivated(1)
        } else {
            setActivated(0)
        }
    }
    // submite
    function PageCreatedSubmitHandler(e) {
        e.preventDefault();
    }

    function pagePreviw() {
        const preview = {
            pageTitle: pageTitle,
            HeadingText: HeadingText,
            BodyText: BodyText,
            Activated: activated,
            type: selectValue
        }
        localStorage.setItem("preview", JSON.stringify(preview))
    }

    return (
        <>
            <section className="faq-main-section page-creation-template">
                <Header title="Page Creation Template" />
                <div className="faq-inner-content">
                    <Row className="mx-auto  mobile-button back-logout-btn pt-2">
                        <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
                            <Link to="/maintenance" className="btn btn-light pl-3 pr-3 pt-2 pb-2" >Back</Link>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
                      <Logout/>
                        </Col>
                    </Row>
                    <div className="divider-mobile"></div>
                    <Row className="mx-auto text-center pt-4 mobile-direction inner-table-form">
                        <Col lg={6} md={12} className="p-2 right-side-table " id="style-2">
                            <BootstrapTable keyField='id' rowClasses="custom-row-class0" hover data={products} columns={columns} rowEvents={rowEvents} />
                        </Col>
                        <div className="divider-mobile"></div>
                        <Col lg={6} md={12} className="p-2">
                            <div className="Service-plan-addsection">
                                <div className="add-button-section">
                                    <Button className="btn btn-success" onClick={newButtonClick}>Add New Item</Button>
                                    {
                                        deletebtnDisable ?
                                            <Button variant="danger" onClick={() => {
                                                const confirmBox = window.confirm(
                                                    "Are you sure you want to delete the selected record?"
                                                )
                                                if (confirmBox === true) {
                                                    deleteButtonClicked()
                                                }
                                            }} >Delete</Button>
                                            :
                                            <Button className="btn btn-danger disable-btn-delete" disabled>Delete</Button>
                                    }
                                </div>
                                <Form onSubmit={(e) => PageCreatedSubmitHandler(e)} className="form-faq-section">
                                    <Form.Group controlId="formBasicCheckbox" className="from-group-section active-status">
                                        <Form.Label>Status:</Form.Label>
                                        <Form.Check type="checkbox" label="Is Active" className="Checked-box" onChange={e => checkboxFun(e)} />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlSelect1" className="d-flex align-items-center select-dropdown-sec">
                                        <Form.Label>Type:</Form.Label>
                                        <Form.Control as="select" value={selectValue} onChange={(e) => selectTypeDropdown(e)}>
                                            <option></option>
                                            <option>Terms of Use</option>
                                            <option>Privacy Policy</option>
                                            <option>Support</option>
                                            <option>Read Me</option>
                                            <option>How It Works</option>
                                        </Form.Control>
                                        <Button className="ml-auto">PUBLISH!</Button>
                                    </Form.Group>
                                    <Form.Group controlId="formPlaintextService">
                                        <Form.Label >
                                            Page Title:
                                        </Form.Label>
                                        <Form.Control type="text" value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className=" preview-editor-sec mb-4">
                                        <Form.Label>Heading Text:</Form.Label>
                                        <Editor
                                            textIntro={editorState}
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            ref={editorReference}
                                            id="editor1"
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'embedded',],

                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                // remove: { icon: eraser, className: undefined, component: undefined },
                                            }}
                                            ref={editorReference}

                                            onEditorStateChange={(editorState) => onEditorStateChange(editorState)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="preview-editor-sec sec-editor-section">
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <Form.Label className="m-0 ">Body Text:</Form.Label>
                                            <Form.Label><Link to="/page-creation/preview"
                                                onClick={() => pagePreviw()}
                                                className="btn float-right preview-btn btn-danger  m-0">Preview</Link></Form.Label>
                                        </div>
                                        <Editor
                                            textIntro={editorBodyState}
                                            editorState={editorBodyState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'embedded',],
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                // remove: { icon: eraser, className: undefined, component: undefined },
                                            }}
                                            onEditorStateChange={(editorBodyState) => onEditorState1Change(editorBodyState)}

                                        />
                                    </Form.Group>
                                    <Button className="submite-button " type="submit">
                                        Save
                                     </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default PageCreation

import React, { useEffect, useState } from 'react';
import Header from '../../component/header/Header'
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import './preview.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Logout from '../../../components/logout/MaintainenceLogout';

const PreviewFAQ = (props) => {
    const [faqGet, setFaqGet] = useState([])
    let FAQthequestion = localStorage.getItem('thequestion')
    let FAQtheanswer = localStorage.getItem('theanswer')
    let editbtnFAq = localStorage.getItem('editbtnFAq')
    let FAQID = localStorage.getItem('FaqId')
    // get FaqGet Data
    function getFaqQuestion() {
        axios.get(`${process.env.REACT_APP_SERVICE_PLAN_API}faq`)
            .then(function (response) {
                if (editbtnFAq == 'true') {
                    response.data.forEach(element => {
                        if (element.id == FAQID) {
                            element.thequestion = FAQthequestion;
                            element.theanswer = FAQtheanswer;
                        }
                    });

                }
                setFaqGet(response.data)
            })
    }

    useEffect(() => {
        getFaqQuestion()
    }, [])

    return (
        <div className="preview-section common-font-section">
            <Header title="FAQ Preview" />
            <div className="faq-main-section">
                <Row className="mx-auto  mobile-button back-logout-btn pt-2">
                    <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
                        <Link to="/admin-faq" className="btn btn-light  pl-3 pr-3 pt-2 pb-2" >Back</Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
                        <Logout />
                    </Col>
                </Row>
            </div>

            <div className="preview-content ">
                <Container>
                    {
                        faqGet.map((item, i) => (
                            <div className="faq-inner-content" key={i}>
                                <div className="faq-content-div">
                                    <h5 className="pr-2">Q:</h5>
                                    <h5
                                        dangerouslySetInnerHTML={{
                                            __html: item.thequestion
                                        }}
                                    />
                                </div>
                                <div className="faq-content-div">
                                    <strong className="pr-3">A:</strong>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: item.theanswer
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    }
                    {
                        editbtnFAq == 'false' ?
                            <div className="faq-inner-content" >
                                <div className="faq-content-div">
                                    <h5 className="pr-2">Q:</h5>
                                    <h5
                                        dangerouslySetInnerHTML={{
                                            __html: FAQthequestion
                                        }}
                                    />
                                </div>
                                <div className="faq-content-div">
                                    <strong className="pr-3">A:</strong>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: FAQtheanswer
                                        }}
                                    />
                                </div>
                            </div>
                            : ''
                    }

                </Container>
            </div>
        </div>
    )
}

export default PreviewFAQ
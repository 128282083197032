import React from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Filecreate from '../../assets/images/file.png';
import Tick from '../../assets/images/tick.png'

const servicePlan = ({openModal}) => {

    function ModalOpen() {
        openModal()
    }

    return (
        <section className="serviceplans-section mt-5 ">

            <Container>
                <h2 className="text-center mb-5">Service Plans</h2>
                <div className="d-flex justify-content-between card-flex-section">
                    <Card >
                        <Card.Body>
                            <div className="price-content-sectionA">
                                <h3>$4.99</h3>
                                <span>Per month</span>
                                <h4>$48.00 per Year</h4>
                            </div>
                            <Card.Title>Silver Plan</Card.Title>
                            <div className="create-account-btn">
                                <Link className="btn" onClick={()=>ModalOpen()}><span className="images-section"><img src={Filecreate} /></span> Create an Account</Link>
                            </div>
                            <div className="includes-content">
                                <h5>Includes:</h5>
                                <p> <span><img src={Tick}/></span>2GB Disk Storage - The Vault</p>
                                <p> <span><img src={Tick}/></span> Up to 3 ICE Contacts</p>
                                <p> <span><img src={Tick}/></span> File Uploads</p>
                                <p> <span><img src={Tick}/></span> Items can be Shared</p>
                                <p> <span><img src={Tick}/></span> Items can be kept Private</p>
                                <p> <span><img src={Tick}/></span> 30 Day Checkin</p>
                                <p> <span><img src={Tick}/></span> Break The Glass Trigger Release</p>
                                <p> <span><img src={Tick}/></span> Release of Configured Items</p>
                                <p> <span><img src={Tick}/></span> Receive Text Messages</p>
                                <p> <span><img src={Tick}/></span> Receive Email</p>
                                <p> <span><img src={Tick}/></span> Dispatch Operator</p>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Body>
                            <div className="price-content-sectionA">
                                <h3>$9.99</h3>
                                <span>Per month</span>
                                <h4>$99.00 per year</h4>
                            </div>
                            <Card.Title>SILVER PLAN</Card.Title>
                            <div className="create-account-btn">
                                <Link className="btn" onClick={()=>ModalOpen()}><span className="images-section"><img src={Filecreate} /></span> Create an Account</Link>
                            </div>
                            <div className="includes-content">
                                <h5>Includes:</h5>
                                <p> <span><img src={Tick}/></span>2GB Disk Storage - The Vault</p>
                                <p> <span><img src={Tick}/></span> Up to 3 ICE Contacts</p>
                                <p> <span><img src={Tick}/></span> File Uploads</p>
                                <p> <span><img src={Tick}/></span> Items can be Shared</p>
                                <p> <span><img src={Tick}/></span> Items can be kept Private</p>
                                <p> <span><img src={Tick}/></span> 7 Day Checkin</p>
                                <p> <span><img src={Tick}/></span> Break The Glass Trigger Release</p>
                                <p> <span><img src={Tick}/></span> Release of Configured Items</p>
                                <p> <span><img src={Tick}/></span> Receive Text Messages</p>
                                <p> <span><img src={Tick}/></span> Receive Email</p>
                                <p> <span><img src={Tick}/></span> Dispatch Operator</p>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Body>
                            <div className="price-content-sectionA">
                                <h3>$14.99</h3>
                                <span>Per month</span>
                                <h4>$150.00 per year</h4>
                            </div>
                            <Card.Title>PLATINUM PLAN</Card.Title>
                            <div className="create-account-btn">
                                <Link className="btn" onClick={()=>ModalOpen()}><span className="images-section"><img src={Filecreate} /></span> Create an Account</Link>
                            </div>
                            <div className="includes-content">
                                <h5>Includes:</h5>
                                <p> <span><img src={Tick}/></span>2GB Disk Storage - The Vault</p>
                                <p> <span><img src={Tick}/></span> Up to 3 ICE Contacts</p>
                                <p> <span><img src={Tick}/></span> File Uploads</p>
                                <p> <span><img src={Tick}/></span> Items can be Shared</p>
                                <p> <span><img src={Tick}/></span> Items can be kept Private</p>
                                <p> <span><img src={Tick}/></span> 2-3 Day Checkin</p>
                                <p> <span><img src={Tick}/></span> Break The Glass Trigger Release</p>
                                <p> <span><img src={Tick}/></span> Release of Configured Items</p>
                                <p> <span><img src={Tick}/></span> Receive Text Messages</p>
                                <p> <span><img src={Tick}/></span> Receive Email</p>
                                <p> <span><img src={Tick}/></span> Dispatch Operator</p>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </section>
    )
}

export default servicePlan

import React, {useEffect, useState} from 'react';
import {Row, Col, Container, Tabs, Tab, Button} from 'react-bootstrap';
import axios from 'axios';
import Header from '../../components/NewHeader/Header';
import {useHistory} from "react-router-dom";
import Back from '../../assets/images/Back_white.png';
import pattern from "../../assets/images/LandingPage/patterns/pattern.jpg";
import pattern_1 from "../../assets/images/LandingPage/patterns/pattern_1.jpg";

const Termsofuse = () => {
    const history = useHistory()
    const [termOfUse, setTermOfUse] = useState([])


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_GMTP_API}/termofuse`)
            .then(function (response) {
                setTermOfUse(response.data)
            })
    }, [])

    const handleBack = () => {
        history.goBack()
    }
    return (
        <>
            <div className="privacy-policy-section position-relative">
                <header>
                    <div className="header-section">
                        <h1 className="text-center">TERMS OF USE</h1>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <div className="background-pattern-img background-loop"
                             style={{backgroundImage: `url(${pattern})`}}/>
                        <div className="background-pattern-gradient"/>
                        <div className="background-pattern-bottom">
                            <div className="image"
                                 style={{backgroundImage: `url(${pattern_1})`}}/>
                        </div>
                    </div>
                </header>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Button onClick={handleBack} className="btn custom-link-btn btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Button>
                    </Col>
                </Row>
                <Container className="bg-white mt-4 p-4">
                    <Row>
                        <div className="work_container">
                    {
                        termOfUse && termOfUse.map((item, i) => (
                            <div key={i}>
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: item.theheader
                                    }}
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: item.thetext
                                    }}
                                />
                            </div>
                        ))
                    }
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Termsofuse;


import React, {useEffect, useState} from 'react';
import Header from "../../component/header/Header";
import {Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import Back from "../../../assets/images/Back_white.png";
import MaintainenceLogout from "../../../components/logout/MaintainenceLogout";
import moment from "moment";
import axios from "axios";
import "./employeesheet.css"
import DatePicker from "react-datepicker";
import calendar_icon from "../../images/calendar_icon.png";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckInTable from "../../../components/CheckInTable";
import ConfirmationModel from "../../../components/ConfirmationModel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 180,
    },
}));
const EmployeeCallSheet = () => {
    const classes = useStyles();
    const date = new Date();
    const adminId = localStorage.getItem('userid');
    const [show, setShow] = React.useState(false);
    const [checkinDetail, setCheckInDetails] = useState([]);
    const [confirmModel, setConfirmModel] = React.useState(false);
    const [checkinData, setCheckinData] = React.useState([]);
    const currentDate = moment(date).format('YYYY-MM-DD');
    const [showModel, setShowModel] = useState(false);
    const [nCount, setNCount] = useState(0);
    const [isShowOthers, setIsShowOthers] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [IsReminded, setIsReminded] = useState(false);
    const [contactType, setContactType] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [startdate, setStartDate] = useState(new Date());
    const [emergencyContact, setEmergencyContact] = useState([]);
    const [employeeData, setEmployeData] = React.useState([]);
    const [selectedEmploye, setSelectedEmploye] = React.useState(0);
    const [isLoadingEmploye, setIsLoadingEmploye] = React.useState(false);

    useEffect(()=>{
        getAssignEmployees();
        DailyCheckingList(moment(startdate).format('YYYY-MM-DD'))
    },[])

    const DailyCheckingList = (date,id= 0) => {
        setIsLoadingEmploye(true)
        setCheckinData([])
            axios.get(`user/bogetdailycheckinlist?nUserID=${adminId}&dDate=${date}&sSearch=${searchText}&nEmployeeID=${id}`)
                .then((response) => {
                    setCheckinData(response.data);
                    setNCount(response.data[0].nCount);
                    setIsLoadingEmploye(false)
                }).catch((err) => {
                    setIsLoadingEmploye(false)
            });
    };
    const getAssignEmployees = () => {
        axios.get(`user/bodailycheckinGetAssignedEmps?nUserID=${adminId}`)
            .then((response) => {
                setEmployeData(response.data)
            }).catch((err) => {
            console.log(err);
        })
    }
    const handleChange = (event) => {
        setSelectedEmploye(event.target.value)
        DailyCheckingList(moment(startdate).format('YYYY-MM-DD'),event.target.value);
    };

    const handleShowModel = () => {
        setShow(true);
    };
    const confirmationModel = (type) => {
        setConfirmModel(!confirmModel)
        setContactType(type)
    };
    const handleDetailModel = () => {
        setShow(true)
    };

    const handleDetailModelClose = () => {
        setShow(false)
        setCheckInDetails("")
        DailyCheckingList(moment(startdate).format('YYYY-MM-DD'), selectedEmploye);
    };

    const getUserDetailApi = (data) => {
        setSelectedRowData(data);
        const {recordID, userId} = data;
        axios.get(`user/bodailycheckinDetailsByUser?nUserID=${userId}&nRecordID=${recordID}`)
            .then((response) => {
                setCheckInDetails(response.data[0])
            }).catch((err) => {
            console.log(err)
        })
        axios.get(`user/bodailycheckinGetEmergContacts?nUserID=${userId}`)
            .then((response) => {
                setEmergencyContact(response.data)
            }).catch((err) => {
            console.log(err)
        });
    };
    const getPerformUserList = () => {
        setIsReminded(true)
        axios.get('readme?nWhichScreen=41')
            .then((response) => {
                const data = response.data[0]
                const header = data.theheader.replace(/<[^>]+>|\n/g, '');
                const body = data.thetext.replace(/<[^>]+>|\n/g, '');
                checkinData.forEach((user) => {
                    axios.post(`user/bodailycheckinsystemtext`,
                        {
                            nUserID: user.UserID,
                            nRecordID: user.RecordID,
                            nSystemUserID: localStorage.getItem("userid"),
                            subject: header,
                            body: data.thetext.replace('&lt;username&gt;', user.UserName),
                            nTextBody: body.replace('&lt;username&gt;', user.UserName),
                        }).then((response) => {
                        setIsReminded(false)
                        handleCloseModel()
                    }).catch((err) => {
                        setIsReminded(false)
                        console.log(err)
                    })
                })
            })
    };

    const handleCloseModel = () => {
        setShowModel(false)
    }
    const handleRefresh = () => {
        DailyCheckingList(moment(startdate).format('YYYY-MM-DD'), selectedEmploye);
    }

    const handleChangeDate = (date) => {
        setStartDate(date);
        DailyCheckingList(moment(date).format('YYYY-MM-DD'), selectedEmploye);
    }

    const handleCheckboxChange = (e) => {
        setIsShowOthers(e.target.checked);
    }
    const handlePhoneCall = () => {
        const data = {
            nUserID: checkinDetail.UserID,
            nRecordID: checkinDetail.RecordID,
            nSystemUserID: localStorage.getItem('userid'),
            sPhoneCall: contactType
        }
        return axios.post(`user/bodailycheckinCall`, data)
            .then((response) => {
                setConfirmModel(false)
            }).catch((err) => {
                setConfirmModel(false)
            })
    }
    const checkin = moment(startdate).format('YYYY-MM-DD')
    return (
        <>
            <Header title="Employee Call Sheet"/>
            <Modal show={showModel} onHide={handleCloseModel}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title> Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to Send Reminders now?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModel}
                            style={{backgroundColor: "#9c9b9c", color: "white", border: 'none'}}>
                        No
                    </Button>
                    <Button onClick={getPerformUserList}
                            style={{backgroundColor: "#7030a0", color: "white", border: 'none'}}>
                        {IsReminded ? <CircularProgress/> : 'Yes'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <Link to="/maintenance" className="btn custom-link btn-primary">
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </Link>
                </Col>
                <Col
                    lg={6} md={6} sm={6} xs={6} className="btnall"
                >
                    <MaintainenceLogout/>
                </Col>
            </Row>
            <div className="daily_reporting_content mt-3">
                <div className="reporting_title">
                    <h2 className="text-center">{moment(date).format(('MMMM DD, YYYY'))}
                    </h2>
                </div>
            </div>
            <div className="px-3 user_daily_check_table">
                <div className="action_btn d-flex justify-content-between align-items-end mb-3 flex-wrap">
                    <div className="d-flex">
                        <div className={'d-flex align-items-center date_reminder_content'}>
                            <div className="date_picker mr-3">
                                <DatePicker
                                    className="form-control"
                                    selected={startdate}
                                    onChange={(date) => handleChangeDate(date)}
                                />
                                <img src={calendar_icon} alt="calendar_icon"/>
                            </div>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Employees</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={handleChange}
                                    label="Employees"
                                >
                                    <MenuItem value={0}>
                                        <em>None</em>
                                    </MenuItem>
                                    {employeeData.map((emp,key)=>
                                        <MenuItem value={emp.id} key={key}>{emp.fname + ' ' + emp.lname}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="record_count_label d-flex align-items-center flex-wrap">
                        <Form.Group className="m-0 mr-3">
                            <Form.Control
                                placeholder="Search: Account No/Email/Name"
                                type="text"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value ? e.target.value : null)}/>
                        </Form.Group>
                        <button className="btn custom-link btn-primary mr-3" onClick={handleRefresh}>
                            <RefreshIcon/> Refresh
                        </button>
                        <Form.Check
                            className="mr-3"
                            type={'checkbox'}
                            label={'Exclude checked in'}
                            id={`excludecheck`}
                            onChange={(e)=>DailyCheckingList(checkin,`&nExclude=${e.target.checked ? 1 : 0}`)}
                        />
                        <Form.Check
                            className="mr-3"
                            type={'checkbox'}
                            label={'Show Others'}
                            id={`default`}
                            checked={isShowOthers}
                            onChange={e => handleCheckboxChange(e)}
                        />
                        <h5 className="m-0">Record Count : {nCount}</h5>
                    </div>
                </div>
                <CheckInTable
                    checkinData={checkinData}
                    handleShowModel={handleShowModel}
                    getUserDetailApi={getUserDetailApi}
                    isShowOthers={isShowOthers}
                    selectedRowData={selectedRowData}
                    isLoadingEmploye={isLoadingEmploye}
                />
            </div>
            <ConfirmationModel
                IsOpen={confirmModel}
                handleClose={confirmationModel}
                handlePhoneCall={handlePhoneCall}
                contactType={contactType}
                getUserDetailApi={getUserDetailApi}
                data={selectedRowData}
            />
        </>
    );
};

export default EmployeeCallSheet;
import React, {createRef, useEffect} from 'react';
import {Button, Form, Modal, Row} from "react-bootstrap";
import './communicationModel.css'
import axios from "axios";
import moment from "moment";

const NewConversation = (props) => {
    const ref = createRef();
    useEffect(()=>{
        if (ref.current !== null){
            ref.current.focus()
        }
    },[ref])

    const {title, Isopen, handleTabDetail, size, className, userId, notesData,notes,setNotes} = props;

    const handleSaveNotes = () => {
        const data = {
            nUserID: userId,
            nRecordID: notesData ? notesData.RecordID : 0,
            sMessage: notes
        }
        axios.post('user/boCommunicationsSave', data)
            .then((response) => {
                handleTabDetail();
                props.getCommunicationData();
            }).catch((err) => {
            console.log(err)
        })
    }

    const getFormattedDate = (date) =>{
        if (notesData.length !== 0){
            return moment(date).format('MM/DD/YYYY hh:mm:ssA')
        }else {
            return null
        }
    }
    return (
        <>
            <Modal
                centered
                show={Isopen}
                onHide={handleTabDetail}
                backdrop="static"
                style={{zIndex: 1049}}
                keyboard={false}
                size={size}
                className="assign-confirmation-modal"
            >
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}} closeButton>
                    <Modal.Title className={'text-capitalize'}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={className}>
                    <div className={'d-flex align-items-center '}>
                       <div className={'d-flex align-items-center'}>
                           <Form.Label className={'m-0'}>Created:</Form.Label>
                           <Form.Group as={Row} className={'m-0'}>
                               <Form.Control type="text" readOnly={true} value={getFormattedDate(notesData.created)}/>
                           </Form.Group>
                       </div>
                        <div className={'d-flex align-items-center ml-2'}>
                            <Form.Label className={'m-0'}>Last Updated:</Form.Label>
                            <Form.Group as={Row} className={'m-0'}>
                                <Form.Control type="text" readOnly={true} value={getFormattedDate(notesData.updated)}/>
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'communcation-notes'}>
                        <Form.Control
                            ref={ref}
                            as="textarea"
                            type="text"
                            rows={12}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button className={'btn custom-link'} disabled={!notes} onClick={handleSaveNotes}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleTabDetail}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewConversation;

import React, { useEffect, useState } from 'react';
import PageCreation from '../../component/PageCreation/PageCreation';
import PreviewPage from '../../component/PageCreation/PreviewPage'

const PageCreationTemplate = (props) => {
    const [Tab, setTab] = useState('')
    useEffect(() => {
        setTab(props.match.params.id)
    }, [props.match.params.id])
    return (
        <>
            {
                Tab === 'all' ?
                    <PageCreation />
                    : Tab === 'preview' ?
                        <PreviewPage />
                        : ''
            }
        </>
    )
}

export default PageCreationTemplate

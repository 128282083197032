import React, {useEffect, useState} from 'react';
import './header.css';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import SessionExpired from '../SessionExpired/SessionExpired'
import ReactTimeout from '../../ReactTimeout/ReactTimeout'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const Header = (props) => {
    const classes = useStyles();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [TokenExpired, setTokenExpired] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notificationData, setNotificationData] = React.useState([]);
    const [unreadMessages, setUnreadMessages] = React.useState(null);

    useEffect(() => {
        callNotification();
        if (!window.location.pathname.substr(1) === 'maindashboard') {
            profileDetailGet();
        } else if (!window.location.pathname.substr(1) === 'newDashboard') {
            profileDetailGet();
        }
    }, []);

    function profileDetailGet() {
        var token = localStorage.getItem("token");
        axios
            .get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    res &&
                    res.data &&
                    res.data.forEach((element) => {
                        setFname(element.fname);
                        setLname(element.lname);
                        localStorage.setItem("checkinbuttononly", element.checkinbuttononly);
                        localStorage.setItem("contactlayout", element.contactlayout);
                        localStorage.setItem("wishlayout", element.wishlayout);
                        localStorage.setItem("showads", element.showads);
                        localStorage.setItem("newUser", element.newuser);
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const callNotification = () =>{
        const nUserId = localStorage.getItem('userid');
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_SERVER}user/notificationsGet?nUserID=${nUserId}`,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((response)=>{
            const unreadmessages = response.data.filter((unread)=>unread.hasbeenread === 1)
            setNotificationData(response.data)
            setUnreadMessages(unreadmessages.length)
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        callNotification()
    };

    const handleClose = () => {
        setAnchorEl(null);
        const nUserId = localStorage.getItem('userid');
        const token = localStorage.getItem('token');
        axios.post(`${process.env.REACT_APP_SERVER}user/notificationsReSet?nUserID=${nUserId}`,{},
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((response)=>{
            callNotification()
        })
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <ReactTimeout/>
            {
                TokenExpired &&
                window.location.pathname !== '/privacy-policy' &&
                window.location.pathname !== '/terms-of-use' &&
                window.location.pathname !== '/how-its-work' &&
                window.location.pathname !== '/faq'
                && <SessionExpired/>
            }
            <header>
                <div className="header-section">
                    <h1 className="text-center">{props.title}</h1>
                    {
                        props.removeloggged ?
                            ""
                            :
                            <div className="right-side-text d-flex text-center">
                                <div className="notification_icon d-flex mr-4">
                                    <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                                        <Badge badgeContent={unreadMessages && unreadMessages} color="secondary">
                                            <NotificationsNoneIcon />
                                        </Badge>
                                    </Button>
                                    <div className={'test'}>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <div className={'notification_content'}>
                                                {
                                                    notificationData.length !== 0 ?
                                                        (
                                                            notificationData.map((notification)=>
                                                                <div className="d-flex notification_body">
                                                                    <div className="content_img">
                                                                        <img src={notification.filelocation+notification.s3filename} width="100%" alt="defaultProfile"/>
                                                                    </div>
                                                                    <div className="notification_details">
                                                                        <h5 className="mb-1">{notification.fname + ' ' + notification.lname}</h5>
                                                                        <Typography className={classes.typography}>{notification.msg}</Typography>
                                                                        <p className="text-right notification_time">{moment(notification.created).format('DD/MM/YYYY hh:mm')}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                        : (
                                                            <div className="d-flex notification_body">
                                                                <div className="notification_details">
                                                                    <Typography className={classes.typography}>You don't have any notification!</Typography>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </Popover>
                                    </div>
                                </div>
                                {fname
                                    ?<h6 className="m-0"> Logged in as : <span className={'text-capitalize'}>{fname}{' '}{lname}</span></h6>
                                    : <h6 className="m-0"> Logged in as : <span className={'text-capitalize'}>{localStorage.getItem('name')}</span></h6>
                                }
                            </div>
                    }

                </div>
                <div className="devider"></div>
            </header>
        </>
    )
}
export default Header;
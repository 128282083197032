import React from 'react';
import {Button, Modal} from "react-bootstrap";

const HandleConfirmationModel = (props) => {
    const {IsOpen,handleClose,handleConfirm,question,title} = props;
    return (
        <Modal
            centered
            show={IsOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="assign-confirmation-modal"
        >
            <Modal.Header style={{ backgroundColor: "#7030a0", color: "white" }} closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {question}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                {handleConfirm && <Button className="btn-yes" onClick={handleConfirm}>Yes</Button>}
            </Modal.Footer>
        </Modal>
    );
};

export default HandleConfirmationModel;

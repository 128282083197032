import React from 'react';
import ReactDOM from 'react-dom';
import './footer.css';
import { Row, Col,Navbar,Nav, } from 'react-bootstrap';

const Footer = () =>{
    return(
        <>

            {/*<Navbar bg="light" expand="lg" className="footer">
                <Nav className="m-auto footer-nav">
                    <Nav.Link href="#/game-rules">GAME RULES</Nav.Link>
                    <Nav.Link href="#/support">SUPPORT</Nav.Link>
                    <Nav.Link href="#/how-it-works">HOW IT WORKS</Nav.Link>
                    <Nav.Link href="#/privacy-policy">PRIVACY POLICY</Nav.Link>
                    <Nav.Link href="#/terms-of-use">TERMS OF USE</Nav.Link>
                </Nav>
            </Navbar>*/}
        </>
    );
}
export default Footer;
import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../admin/component/header/Header';
import {Row, Col, Button, Form} from 'react-bootstrap';
import Back from '../../../assets/images/Back_white.png'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import ReportingTable from "../../../components/ReportingTable/ReportingTable";
import calendar_icon from "../../images/calendar_icon.png";
import DatePicker from "react-datepicker";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import "./reporting.css"
import moment from "moment";

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 180,
    },
}));

const reportingMenu = [
    {
        "id": 1,
        "title": "Application Users",
    }, {
        "id": 2,
        "title": "New Sign Ups",
    }, {
        "id": 3,
        "title": "New Subscribers",
    }, {
        "id": 4,
        "title": "New User's Expired",
    }, {
        "id": 5,
        "title": "User Carry Over's",
    }, {
        "id": 6,
        "title": "User Account # 0's",
    },
];
const Reporting = () => {
    const classes = useStyles();
    const adminId = localStorage.getItem('userid');
    const [startdate, setStartDate] = useState(new Date());
    const [enddate, setEndDate] = useState(new Date());
    const [reportingId, setReporting] = useState(0);
    const [reportingData, setReportingData] = useState([]);
    const [headerText, setHeaderText] = useState(null);
    const [count, setCount] = useState(0);

    const handleStartDate = (date) => {
        setStartDate(date)
    }
    const handleEndDate = (date) => {
        setEndDate(date);
    }
    const formatDate = (date) =>{
        return moment(date).format('YYYY-MM-DD')
    }

    const getReportingData = () =>{
        const startingDate = startdate ? `&fromDate=${formatDate(startdate)}` : '';
        const endingDate = enddate ? `&toDate=${formatDate(enddate)}` : '';
         axios.get(`user/boUserReporting?nUserID=${adminId}&nReportID=${reportingId}${startingDate}${endingDate}`)
             .then((response)=>{
                 setReportingData(response.data);
                 setCount(response.data ? response.data[0].Count : 0)
             }).catch((err)=>{
             setCount(0)
         })
    }
    const handleChange = (e) =>{
        const selected = reportingMenu.find((ev)=>ev.id === e.target.value)
        setReporting(e.target.value);
        setHeaderText(selected ? selected.title : '');
    }
    return (
        <section className={`reporting-section`}>
            <Header title="Reporting" />
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <Link to="/maintenance" className="btn custom-link btn-primary">
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </Link>
                </Col>
                <Col
                    lg={6} md={6} sm={6} xs={6} className="btnall"
                >
                    <MaintainenceLogout/>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className={'reporting-table'}>
                    <p className={'reporting-table-header'}>{headerText}</p>
                    <div className={'date-picker-container'}>
                        <div className={'date-picker-icon'}>
                            <DatePicker
                                className="date_picker"
                                selected={startdate}
                                onChange={(date) => handleStartDate(date)}
                                placeholderText="Start Date"
                            />
                            <img src={calendar_icon} alt="calendar_icon" height={'25px'}/>
                        </div>
                        <div className={'date-picker-icon'}>
                            <DatePicker
                                className="date_picker"
                                selected={enddate}
                                onChange={(date) => handleEndDate(date)}
                                placeholderText="To Date"
                            />
                            <img src={calendar_icon} alt="calendar_icon" height={'25px'}/>
                        </div>
                        <Button className={'custom-link'} onClick={getReportingData} disabled={!startdate || !enddate}>Go</Button>
                    </div>
                    <div className={'report-container'}>
                        <div className={'select_type'}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Reporting</InputLabel>
                                <Select
                                    labelId="employe-reporting-label"
                                    onChange={handleChange}
                                    label="ReportingId"
                                >
                                    {reportingMenu.map((emp,key)=>
                                        <MenuItem value={emp.id} key={key}>{emp.title}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <Form.Group as={Row} className="countController">
                            <Form.Label>Count:</Form.Label>
                            <Form.Control type="text" readOnly={true} value={count}/>
                        </Form.Group>
                    </div>
                    <ReportingTable
                        reportingData={reportingData}
                        isShowOthers={true}
                        isLoadingEmploye={false}
                    />
                </Col>
            </Row>
        </section>
    )
}

export default Reporting

import React, {useEffect, useState} from 'react';
import './header.css';
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import SessionExpiredAdmin from '../../../components/SessionExpired/SessionExpiredAdmin'

const Header = (props) => {
    const history = useHistory()
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [TokenExpired, setTokenExpired] = useState(false);

    function profileDetailGet() {
        const data = {
            nUserID: localStorage.getItem("userid"),
        };
        var token = localStorage.getItem("maintenance-token");
        axios
            .get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    res &&
                    res.data &&
                    res.data.forEach((element) => {
                        setFname(element.fname);
                        setLname(element.lname);
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        profileDetailGet();
    }, []);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <header>
                {
                    TokenExpired
                    && <SessionExpiredAdmin/>
                }

                <div className="header-section">
                    <h1 className="text-center">{props.title}</h1>
                    <div className="right-side-text text-center">
                        <h6> Logged in as
                            : <span>{capitalizeFirstLetter(fname)}{' '}{capitalizeFirstLetter(lname)}</span></h6>
                    </div>
                </div>
                <div className="devider"></div>
            </header>
        </>
    )
}

export default Header

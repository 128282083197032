import React, {useEffect, useRef, useState} from 'react';
import Header from '../../../admin/component/header/Header';
import {Row, Col, Form, Button,} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {Link, useHistory} from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {Editor} from '@tinymce/tinymce-react';
import axios from 'axios';
import $ from "jquery";
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import SessionExpiredAdmin from '../../../components/SessionExpired/SessionExpiredAdmin'
import { makeStyles } from '@material-ui/core/styles';
import Back from '../../../assets/images/Back_white.png'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
const PrivacyPolicy = () => {
    const history = useHistory()
    const [deletebtnDisable, setDeletebtnDisable] = useState(false)
    const [policyGet, setPolicyGet] = useState([])
    const [theHeader, setTheHeader] = useState('');
    const [theText, setTheText] = useState('')
    const [policyRow, setPolicyRowID] = useState(localStorage.getItem('policyRowID'))
    const [statusPolicy, setStatusPolicy] = useState(0)
    const [fData, setFData] = useState(true)
    const [on, seton] = useState()
    const [previewDisAble, setPreviewDisAble] = useState(false)
    // const [previewDisable, setPreviewDisable] = useState(localStorage.getItem("PreviewDisAble"))
    // local storage
    const [policyStatusActiveLS, setTermStatusActiveLS] = useState(localStorage.getItem("policyStatusActive"))
    const [theHeaderPolicyEditor, settheHeaderPolicyEditor] = useState(localStorage.getItem("policytheHeaderEditor"))
    const [thePolicyTextLS, setthePolicyTextLS] = useState(localStorage.getItem("thePolicyText"))
    const [TokenExpired, setTokenExpired] = useState(false);

    const [isSaved, setIsSaved] = useState(false)

    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    // get Data
    const columns = [
        {
            dataField: 'theheader',
            text: 'Version',
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === policyRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            }, formatter: (cell) => {
                if (cell) {
                    return (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cell
                            }}
                        />
                    );
                }
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === policyRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },

            sort: true
        }, {
            dataField: 'statusid',
            text: 'Status',
            formatter: (cell) => {
                if (cell == "1") {
                    return (
                        <span>
                            <strong style={{color: '#28a745'}}> Active </strong>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            <strong style={{color: '#dc3545'}}> In Active </strong>
                        </span>
                    );
                }
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === policyRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }, {
            dataField: 'created',
            text: 'Created',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            sortValue: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell).getTime();
                }
                return dateObj;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === policyRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true

        }, {
            dataField: 'updated',
            text: 'Updated',
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return cell;
            },
            sortValue: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell).getTime();
                }
                return dateObj;
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (row.id === policyRow) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            sort: true
        }
    ];

    function getPolicyQuestion() {
        setOpen(true)
        axios.get(`${process.env.REACT_APP_SERVICE_PLAN_API}privacypolicy`)
            .then(function (response) {
                if (response.data.status) {
                    setTokenExpired(true)
                }else {
                    setPolicyGet(response.data)
                    setOpen(false)
                }
            })
    }

    useEffect(() => {
        getPolicyQuestion()
    }, [])
    // localStorage
    useEffect(() => {
        if (policyStatusActiveLS && theHeaderPolicyEditor && thePolicyTextLS) {
            setStatusPolicy(policyStatusActiveLS)
            setTheHeader(theHeaderPolicyEditor);
            setTheText(thePolicyTextLS);

            if (policyStatusActiveLS == 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
                setStatusPolicy(policyStatusActiveLS)
            }
        }
    }, [])


    // edirot header
    const handleEditorChange = (value, editor) => {
        setTheHeader(value)
    }
    // editor theText Body
    const handleEditorChangetheText = (value, editor) => {
        setTheText(value)
    }
    // Table Row Selected
    const rowEvents = {
        onClick: (e, row) => {
            getPolicyQuestion()
            setPreviewDisAble(true)
            setTheHeader(row.theheader)
            setTheText(row.thetext)
            setPolicyRowID(row.id)
            setDeletebtnDisable(true)
            localStorage.setItem('editbtnPolicy', true)
            if (row.statusid == 1) {
                $(`#formBasicCheckbox`).prop("checked", true)
                setStatusPolicy(row.statusid)
            } else {
                $(`#formBasicCheckbox`).prop("checked", false)
                setStatusPolicy(row.statusid)
            }
        }
    }
    // checkbox
    const checkboxChanger = (e) => {
        let target = e.target;
        if (target.checked) {
            setStatusPolicy(1)
        } else {
            setStatusPolicy(0)
            localStorage.setItem('policyStatusActive', 0)
        }
    }

    // Preview
    function hnadlerPreviewbtn() {
        localStorage.setItem('policytheHeaderEditor', theHeader);
        localStorage.setItem('thePolicyText', theText);
        localStorage.setItem('policyStatusActive', statusPolicy);
        localStorage.setItem("policyRowID", policyRow);
    }

    // Add New Btn
    const newButtonClick = () => {
        setPreviewDisAble(false)
        setTheHeader('')
        setTheText('')
        setStatusPolicy(1)
        setDeletebtnDisable(false)
        setFData(true)
        if (document.getElementById('demo_ifr').focus()) {
            setPreviewDisAble(false)
        }
        document.getElementById('demo_ifr').focus()
        $(`#formBasicCheckbox`).prop("checked", true)
        localStorage.setItem('PreviewDisAble', false)
        localStorage.setItem('policytheHeaderEditor', '')
        localStorage.setItem('thePolicyText', '')
        localStorage.setItem('policyStatusActive', 1)
        localStorage.setItem('editbtnPolicy', false)
        localStorage.setItem("policyRowID", '')
    }
    // delete 

    const deleteButtonClicked = () => {
        const data = {
            nID: policyRow,
        };
        var token = localStorage.getItem('maintenance-token');
        axios.post(process.env.REACT_APP_SERVICE_PLAN_API + 'page/privacypolicydelete', data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then(res => {
            if (res.data.status) {
                setTokenExpired(true)
            }else {
                setTheHeader('')
                setTheText('')
                $(`#formBasicCheckbox`).prop("checked", false)
                document.getElementById('demo_ifr').focus()
                localStorage.setItem("policyStatusActive", '')
                localStorage.setItem("policytheHeaderEditor", '')
                localStorage.setItem("thePolicyText", '')
                localStorage.setItem("policyRowID", '')
                localStorage.setItem('editbtnPolicy', false)
                getPolicyQuestion()
            }
        }).catch(err => {
            console.log(err);

        });


    }
    // submit 
    const termsSubmitHandler = (e) => {
        setIsSaved(true)
        e.preventDefault()
        let editBtn = localStorage.getItem('editbtnPolicy')
        if (editBtn == "false") {
            const data = {
                nID: '',
                sHeader: theHeader,
                sText: theText,
                nIsActive: statusPolicy
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}page/privacysave`, data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setIsSaved(false)
                    setDeletebtnDisable(false)
                    setTheHeader('')
                    setTheText('')
                    $(`#formBasicCheckbox`).prop("checked", false)
                    localStorage.setItem("policyStatusActive", '')
                    localStorage.setItem("policytheHeaderEditor", '')
                    localStorage.setItem("thePolicyText", '')
                    localStorage.setItem("policyRowID", '')
                    localStorage.setItem('editbtnPolicy', false)
                    getPolicyQuestion()
                }
            }).catch(err => {
                console.log(err);

            });
        } else {
            const data = {
                nID: policyRow == '' ? localStorage.getItem("policyRowID") : policyRow,
                sHeader: theHeader,
                sText: theText,
                nIsActive: statusPolicy
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}page/privacysave`, data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setDeletebtnDisable(false)
                    setTheHeader('')
                    setTheText('')
                    $(`#formBasicCheckbox`).prop("checked", false)
                    localStorage.setItem("policyStatusActive", '')
                    localStorage.setItem("policytheHeaderEditor", '')
                    localStorage.setItem("thePolicyText", '')
                    localStorage.setItem("policyRowID", '')
                    localStorage.setItem('editbtnPolicy', false)
                    getPolicyQuestion()
                }
            }).catch(err => {
                console.log(err);

            });
        }
        getPolicyQuestion()
    }

    function BackBtnfun() {
        $(`#formBasicCheckbox`).prop("checked", false)
        localStorage.setItem("policyStatusActive", '')
        localStorage.setItem("policytheHeaderEditor", '')
        localStorage.setItem("thePolicyText", '')
        localStorage.setItem("policyRowID", '')
        localStorage.setItem('editbtnPolicy', false)
        history.push("/maintenance")
    }

    return (
        <>
            {
                TokenExpired
                && <SessionExpiredAdmin/>
            }

            <section className="faq-main-section terms-of-use-main-section common-font-section">
                <Header title=" Privacy Policy"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maintenance" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <MaintainenceLogout/>
                    </Col>
                </Row>
                <div className="faq-inner-content">
                    <div className="divider-mobile"></div>
                    <Row className="mx-auto text-center pt-4 mobile-direction terms-of-use-table inner-table-form">
                        <Col lg={5} md={12} className="p-2 right-side-table " id="style-2">
                            <BootstrapTable keyField='id' rowClasses="custom-row-class0" hover data={policyGet}
                                            columns={columns} rowEvents={rowEvents}/>
                        </Col>
                        <div className="divider-mobile"></div>
                        <Col lg={7} md={12} className="p-2">
                            <div className="Service-plan-addsection">

                                <Form onSubmit={(e) => termsSubmitHandler(e)} className="form-faq-section ">
                                    <Form.Group controlId="formBasicCheckbox"
                                                className="from-group-section active-status">
                                        <Form.Label>Status:</Form.Label>
                                        <Form.Check type="checkbox" label="Is Active" className="Checked-box"
                                                    onChange={e => checkboxChanger(e)}/>
                                    </Form.Group>
                                    <Form.Group className=" editor-data-new">
                                        <div className="terms-of-use-add-delet-btn">
                                            <Form.Label>Header:</Form.Label>
                                            <div className="add-button-section">
                                                <Button className="btn btn-success" onClick={newButtonClick}>Add New
                                                    Item</Button>
                                                {
                                                    deletebtnDisable ?
                                                        <Button variant="danger" onClick={() => {
                                                            const confirmBox = window.confirm(
                                                                "Are you sure you want to delete the selected record?"
                                                            )
                                                            if (confirmBox === true) {
                                                                deleteButtonClicked()
                                                            }
                                                        }}>Delete</Button>
                                                        :
                                                        <Button className="btn btn-danger disable-btn-delete"
                                                                disabled>Delete</Button>
                                                }
                                            </div>
                                        </div>
                                        <Editor apiKey='97one8fo56cr08pc620j9rhz2pku2c3kvkxfg3xrs0n8absc'
                                                value={theHeader}
                                                id="demo"
                                                init={{
                                                    plugins: " fullpage  fullscreen  link advlist lists  ",
                                                    toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                    content_style: "body p { line-height: 0.5; }"
                                                }}
                                                onEditorChange={handleEditorChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="preview-editor-sec-enw">
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <Form.Label className="m-0 ">Body:</Form.Label>

                                            {
                                                previewDisAble ?
                                                    <Form.Label><Link to="/privacy-policy-preview"
                                                                      onClick={() => hnadlerPreviewbtn()}
                                                                      className="btn float-right preview-btn btn-danger  m-0">Preview</Link></Form.Label>
                                                    :
                                                    <Button className="btn btn-danger disable-btn-delete preview"
                                                            disabled>Preview</Button>
                                            }
                                        </div>
                                        <Editor apiKey='97one8fo56cr08pc620j9rhz2pku2c3kvkxfg3xrs0n8absc'
                                                value={theText}
                                                init={{
                                                    plugins: "  fullpage  fullscreen  link advlist lists  ",
                                                    toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                    content_style: "body p { line-height: 0.5; }"
                                                }}
                                                onEditorChange={handleEditorChangetheText}
                                        />
                                    </Form.Group>
                                    <Button className="submite-button " type="submit" style={{height:'45px'}}>
                                        Save
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy


import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import './ReportingTable.css'
import moment from "moment";

const ReportingTable = (props) => {
    const {reportingData, selectedRowData} = props;
    if (reportingData.status) {
        return null
    }
    const columns = [
        {
            dataField: 'accountnumber',
            text: 'Account #',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '7%'}
            },
            align: 'center',
            sort: true,
        }, {
            dataField: 'fname',
            text: 'First Name',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '7%','padding':'8px'}
            },
            sort: false
        }, {
            dataField: 'lname',
            text: 'Last Name',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '7%','padding':'8px'}
            },
            sort: true
        }, {
            dataField: 'phone',
            text: 'Phone',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '8%'}
            },
            sort: false
        }, {
            dataField: 'email',
            text: 'Email',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '16%'}
            },
            sort: false,
        }, {
            dataField: 'created_at',
            text: 'Created Date',
            formatter: (created_at) => {
                return (
                    <span>{created_at ? moment(created_at).format('MM/DD/YYYY') : ''}</span>
                )
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'text-align': 'center','width':'8%'}
            },
            align: 'center',
            sort: false
        }, {
            dataField: 'ServicePlan',
            text: 'Service Plan',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '13%','text-align':'center'}
            },
            align: 'center',
            sort: false
        }, {
            dataField: 'nextcheckindate',
            text: `Next Check \nIn`,
            formatter: (nextcheckindate) => {
                return (
                    <span>{nextcheckindate ? moment(nextcheckindate).format('MM/DD/YYYY') : ''}</span>
                )
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF',
                    };
                }
            },
            headerStyle: () => {
                return {'text-align': 'center', 'width': '8%'}
            },
            align: 'center',
            sort: false
        }, {
            dataField: 'lastcheckindate',
            text: 'Last Checked In',
            formatter: (lastcheckindate) => {
                return (
                    <span>{lastcheckindate ? moment(lastcheckindate).format('MM/DD/YYYY hh:mmA') : ''}</span>
                )
            },
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'text-align': 'center','width':'12%'}
            },
            align: 'center',
            sort: false
        },
        {
            dataField: 'EmergencyContacts',
            text: 'Emer \nContacts',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '30px', 'padding': '12px 0px', 'text-align': 'center','white-space':'break-spaces'}
            },
            align: 'center',
            sort: true
        },
        {
            dataField: 'DiskUsage',
            text: 'Disk \nSpace',
            style: (cell, row) => {
                if (row.UserID === (selectedRowData && selectedRowData.userId)) {
                    return {
                        backgroundColor: '#F0F8FF'
                    };
                }
            },
            headerStyle: () => {
                return {'width': '35px','white-space':'break-spaces','text-align':'center'}
            },
            align: 'center',
            sort: true
        }
    ];
    // const rowEvents = {
    //     onClick: (e, row) => {
    //         console.log(row)
    //     }
    // }
    return (
        <div className={'ReportingTable'}>
            <BootstrapTable
                keyField="id"
                data={reportingData}
                columns={columns}
                hover={true}
                // rowEvents={rowEvents}
            />
        </div>
    );
};

export default ReportingTable;

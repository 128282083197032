import React from 'react'
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {Button} from "react-bootstrap";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const MaintainenceLogout = () => {
    const history = useHistory();
    const logoutHandler = () => {
        let userId = localStorage.getItem('userid')
        const data = {
            nUserID: userId
        }
        var token = localStorage.getItem('maintenance-token');
        axios.post(`${process.env.REACT_APP_SERVICE_PLAN_API}auth/logout`, data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then(res => {
            localStorage.removeItem('maintenance-token');
            history.push("/maintenance-login")
        })
            .catch(e => {
                localStorage.removeItem('maintenance-token');
                history.push("/maintenance-login")
            })
    }


    return (
        <div style={{float: 'right'}} className={'LogoutBtn'}>
            <Button className="btn btn-light border btn btn-primary " onClick={() => logoutHandler()}>
                <span className={'d-flex align-items-center justify-content-center font-weight-normal'}><ExitToAppIcon/>&nbsp;LogOut</span>
            </Button>
        </div>
    )
}

export default MaintainenceLogout

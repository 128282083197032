import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Link, useHistory} from "react-router-dom";
import {Form, FormControl, FormGroup, Button, Alert} from 'react-bootstrap';
import axios from 'axios';
import andriodApp from '../../assets/images/androidApp.png'
import appleApp from '../../assets/images/appleApp.png'
import CircularProgress from '@material-ui/core/CircularProgress';


const Loginform = () => {
    let history = useHistory();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loginerror, setemailerror] = useState(false);
    const [errormessage, seterrormessage] = useState('');

    const [isProgress, setIsProgress] = React.useState(false);
    useEffect(() => {
        seterrormessage('Wrong username/password');
    });
    const submitClickBtn = (e) => {
        setIsProgress(true)
        e.preventDefault();
        const data = {
            email: username,
            password: password
        };
        axios.post(process.env.REACT_APP_SERVER + 'auth/login', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setIsProgress(false)
            // console.log(res.data);
            localStorage.setItem('maintenance-token', res.data.token);
            localStorage.setItem('login', true);
            localStorage.setItem('userid', res.data.users.id);
            localStorage.setItem('email', res.data.users.email);
            localStorage.setItem('name', res.data.users.name);
            history.push('/maintenance');
        }).catch(err => {
            setIsProgress(false)
            setemailerror(true);
            console.log(err);
            seterrormessage('Wrong Username/Password');
        });
        // history.push('/maindashboard');
    }

    const styles = {
        img1: {
            marginRight: '5px',
            height: '50px',
            marginBottom: '5px'
        },
        img2: {
            height: '50px',
            width: '169.22px',
            marginBottom: '5px'
        },
        appContainer: {
            display: 'flex',
            justifyContent: 'center',
        }
    }

    const coomingSoon = () => {
        alert("comming soon");
    }
    return (
        <>
            <div className="p-1">
                <Form onSubmit={submitClickBtn}>
                    {loginerror === true &&
                    <Alert key="wrong-login" variant="danger">
                        {errormessage}
                    </Alert>
                    }
                    <Form.Group controlId="exampleForm.ControlInput2">
                        <Form.Control type="text" placeholder="Username/Email" required
                                      onChange={e => setUsername(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                        <Form.Control type="password" placeholder="Password" required
                                      onChange={e => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="btn-lg btn-block custom-btn"
                            style={{height: '45px'}}>
                                 <span>Login</span>
                    </Button>
                    <p className="text-left pt-2 pb-2">
                        {/* This is sample text, this is sample text, this is sample text, this is sample text, this is sample text, this is sample text, this is sample text, this is sample text*/}
                    </p>
                </Form>
                <div className="appContainer">
                    <img src={appleApp} className="img1" onClick={coomingSoon}/>
                    <img src={andriodApp} className="img2" onClick={coomingSoon}/>
                </div>
            </div>
        </>
    );
}

export default Loginform;
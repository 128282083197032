import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import './communicationModel.css'
import NewConversation from "./NewConversation";

const CommunicationModel = (props) => {
    const {title,tabDetailModel,handleTabDetail,data,column,size,className,userId} = props;
    const [notesData,setNotesData]= useState([])
    const [notes, setNotes] = React.useState('')
    const [newConversation,setNewConversation] = useState(false)
    const handleNewConversation = () => {
        setNewConversation(!newConversation)
    }
    const rowEvents = {
        onClick: (e, row) => {
            handleNewConversation();
            setNotesData(row);
            setNotes(row.message)
        }
    }
    const newNotesModel = () => {
        setNewConversation(true)
        setNotesData([]);
        setNotes('')
    }
    return (
        <>
            <Modal
                centered
                show={tabDetailModel}
                onHide={handleTabDetail}
                backdrop="static"
                keyboard={false}
                size={size}
                className="assign-confirmation-modal"
                style={{zIndex:1048}}
            >
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}} closeButton>
                    <Modal.Title className={'text-capitalize'}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={className}>
                    <div className={'new-conversation'}>
                        <Button className={'btn custom-link'} onClick={newNotesModel}>
                            New Conversation
                        </Button>
                    </div>
                    <BootstrapTable
                        keyField='id'
                        rowClasses="custom-info-table"
                        hover
                        data={data}
                        columns={column}
                        rowEvents={rowEvents}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTabDetail}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <NewConversation
                title={'Communications'}
                Isopen={newConversation}
                handleTabDetail={handleNewConversation}
                size={'xl'}
                className={'new-communication-model'}
                userId={userId}
                notesData={notesData}
                getCommunicationData={props.getCommunicationData}
                notes={notes}
                setNotes={setNotes}
            />
        </>
    );
};

export default CommunicationModel;

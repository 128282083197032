import React, {  useState } from 'react'
import Header from '../header/Header'
import {Container } from 'react-bootstrap';
import  './preview.css';

const PreviewPage = () => {
    let previewValue = localStorage.getItem("preview")
    const [preview, setPreview] = useState(JSON.parse(previewValue))
    return (
        <section className="preview-page-all">
            <Header title={preview.pageTitle} />
            <Container className="bg-white mt-4 p-4">
                <div>
                    <h4 dangerouslySetInnerHTML={{
                        __html: preview.HeadingText
                    }} />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: preview.BodyText
                        }}
                    />
                </div>
            </Container>
        </section>
    )
}

export default PreviewPage

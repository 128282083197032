import React, { useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import './communication.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import moment from 'moment';
import Logout from '../../../components/logout/Logout';


const Communication = () => {
    // useState
    const [conversation, setConversation] = useState('')
    const [operator, setOperator] = useState('')
    const [date, setDate] = useState('')
    // Communication
    const CommunicationData = [
        {
            id: "1",
            date: "5/27/2021 6:00PM",
            conversation: "Mr. Thompson complained he wasn't receiving his...",
            operator: "Michelle Brown"
        },
        {
            id: "2",
            date: "5/26/2021 12:04PM",
            conversation: "Mr. Thompson found his wallet. He says our service...",
            operator: "Agnus White"
        },
        {
            id: "3",
            date: "5/01/2021 9:30AM",
            conversation: "The moon was brigthly shining over the ocean water.",
            operator: "Virginia wade"
        },
        {
            id: "4",
            date: "04/30/2021 04:56PM",
            conversation: "Topsy tervy anthing goes.",
            operator: "Protiem Missle"
        },
        {
            id: "5",
            date: "03/31/2021 3:23PM",
            conversation: "the first time this client has placed a complain. It...",
            operator: "Paul Hubbard"
        },

    ]
    const columnsCommunication = [
        {
            dataField: 'date',
            text: 'Date/Time Of Call',
            sort: true
        }, {
            dataField: 'conversation',
            text: 'Conversation',
            sort: true
        }, {
            dataField: 'operator',
            text: 'Operator',
            sort: true
        },
    ];
    // table cell select row
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setConversation(row.conversation)
            setOperator(row.operator)
            setDate(row.date)
        }
    }

    return (
        <>
            <Row className="mx-auto  mobile-button back-logout-btn pt-2 common-font-section">
                <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
                    <Link to="/user-information" className="btn btn-light font-weight-bold border  pl-3 pr-3 pt-2 pb-2" >Back</Link>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
                    <Logout/>
                </Col>
            </Row>
            <section className="communication-detail-section">
                <div className="communication-inner-section d-flex">
                    <div className="w-100 communication-table-section">
                        <div className="table-header-communication">
                            Communication
                        </div>
                        <div className="communication-table-data">
                            <BootstrapTable keyField='id' rowClasses="custom-row-class0" hover data={CommunicationData} columns={columnsCommunication} rowEvents={rowEvents} />
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="divider-mobile"></div>
                        <div className="communication-right-section">
                            <div className="add-new-btn-communication">
                                <Button>New Conversation</Button>
                            </div>
                            <div className="conversation-info">
                                <div className='inner-info-conversation'>
                                    <h6>User Name:</h6>
                                    <p>Username from prior page</p>
                                </div>
                                <div className='inner-info-conversation'>
                                    <h6>Operator:</h6>
                                    <p>{operator}</p>
                                </div>
                                <div className='inner-info-conversation'>
                                    <h6>Date/Time of Call:</h6>
                                    <p>{date}</p>
                                </div>
                                <div className="conversation-chat-info">
                                    <div className="inner-chat-content">
                                        {/* <h6>The Conversation gose here</h6> */}
                                        <p> {conversation}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Communication

import React, {useEffect, useState} from 'react';
import Header from "../../component/header/Header";
import {Col, Row, Table, Form, Modal, Button,} from "react-bootstrap";
import MaintainenceLogout from "../../../components/logout/MaintainenceLogout";
import {Link} from "react-router-dom";
import Back from "../../../assets/images/Back_white.png";
import "./Daily_Reporting.css"
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from "axios";
import CheckInTable from "../../../components/CheckInTable";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar_icon from "../../images/calendar_icon.png"
import CircularProgress from '@material-ui/core/CircularProgress';
    import HandleConfirmationModel from "../../../components/HandleConfirmationModel";

const DailyReporting = () => {
    const date = new Date();
    const adminId = localStorage.getItem('userid');
    const [checkinData, setCheckinData] = React.useState([]);
    const currentDate = moment(date).format('YYYY-MM-DD');
    const [showModel, setShowModel] = useState(false);
    const [nCount, setNCount] = useState(0);
    const [isShowOthers, setIsShowOthers] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [IsReminded, setIsReminded] = useState(false);
    const [assignEmployeModel, setAssignEmploye] = useState(false);
    const [startdate, setStartDate] = useState(new Date());
    const [isLoadingEmploye, setIsLoadingEmploye] = React.useState(false);

    const AssignEmployeModel = () => {
        setAssignEmploye(!assignEmployeModel)
    }
    const assignEmployeApi = () => {
        axios.post(`user/bodailycheckinAssignUsers`, {
            nSystemUserID: adminId,
            dDate: moment(startdate).format('YYYY-MM-DD'),
        }).then((res) => {
                AssignEmployeModel()
                DailyCheckingList(moment(startdate).format('YYYY-MM-DD'));
            }).catch((err) => {
            console.log(err)
        })

    }
    useEffect(() => {
        DailyCheckingList(currentDate);
    }, []);
    const getPerformUserList = () => {
        setIsReminded(true)
        axios.get('readme?nWhichScreen=41')
            .then((response) => {
                const data = response.data[0]
                const header = data.theheader.replace(/<[^>]+>|\n/g, '');
                const body = data.thetext.replace(/<[^>]+>|\n/g, '');
                checkinData.forEach((user) => {
                    axios.post(`user/bodailycheckinsystemtext`,
                        {
                            nUserID: user.UserID,
                            nRecordID: user.RecordID,
                            nSystemUserID: localStorage.getItem("userid"),
                            subject: header,
                            body: data.thetext.replace('&lt;username&gt;', user.UserName),
                            nTextBody: body.replace('&lt;username&gt;', user.UserName),
                        }).then((response) => {
                        setIsReminded(false)
                        handleCloseModel()
                    }).catch((err) => {
                        setIsReminded(false)
                        console.log(err)
                    })
                })
                DailyCheckingList(currentDate);
            })
    };
    const DailyCheckingList = (date,check = '',exclude='') => {
        setIsLoadingEmploye(true)
        setCheckinData([]);
        const defaultURL = `user/bogetdailycheckinlist?nUserID=${adminId}&dDate=${date}&sSearch=${searchText}&nEmployeeID=0${check}${exclude}`
            axios.get(defaultURL)
                .then((response) => {
                    setCheckinData(response.data);
                    setNCount(response.data[0].nCount);
                     setIsLoadingEmploye(false)
                }).catch((err) => {
                 setIsLoadingEmploye(false)
            });
    };

    const handleCloseModel = () => {
        setShowModel(false)
    }
    const handleRefresh = () => {
        DailyCheckingList(moment(startdate).format('YYYY-MM-DD'));
    }

    const handleChangeDate = (date) => {
        setStartDate(date);
        DailyCheckingList(moment(date).format('YYYY-MM-DD'));
    }

    const handleCheckboxChange = (e) => {
        setIsShowOthers(e.target.checked);
    }
    const handleExcludeCheck = (e) =>{
        const exclude = `&nExclude=${e.target.checked ? 1 : 0}`
        const checkin = moment(startdate).format('YYYY-MM-DD')
        DailyCheckingList(checkin,'',exclude)
    }
    return (
        <div>
            <Modal show={showModel} onHide={handleCloseModel}>
                <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                    <Modal.Title> Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to Send Reminders now?</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModel}
                            style={{backgroundColor: "#9c9b9c", color: "white", border: 'none'}}>
                        No
                    </Button>
                    <Button onClick={getPerformUserList} style={{backgroundColor: "#7030a0", color: "white", border: 'none'}}>
                        {IsReminded ? <CircularProgress/> : 'Yes'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header title="Daily Reporting"/>
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <Link to="/maintenance" className="btn custom-link btn-primary">
                        <img src={Back} alt="Back" className="w-5"/> Back
                    </Link>
                </Col>
                <Col
                    lg={6} md={6} sm={6} xs={6} className="btnall"
                ><MaintainenceLogout/>
                </Col>
            </Row>
            <div className="daily_reporting_content">
                <div className="reporting_title">
                    <h2 className="text-center">{moment(date).format(('MMMM DD, YYYY'))}</h2>
                    <br/>
                </div>
            </div>
            <div className="px-3 user_daily_check_table">
                <div className="action_btn d-flex justify-content-between align-items-end mb-3 flex-wrap">
                    <div className="d-flex">
                        <div className={'d-flex align-items-center date_reminder_content'}>
                            <div className="date_picker">
                                <DatePicker
                                    className="form-control"
                                    selected={startdate}
                                    onChange={(date) => handleChangeDate(date)}
                                />
                                <img src={calendar_icon} alt="calendar_icon"/>
                            </div>
                            <button className="btn custom-link btn-primary" onClick={() => setShowModel(true)}>
                                <NotificationsNoneIcon/> Send Reminder
                            </button>
                            <Button className="custom-link ml-1" onClick={AssignEmployeModel} disabled={!isShowOthers}>
                                Assign Emp/Users
                            </Button>
                        </div>
                    </div>
                    <div className="record_count_label d-flex align-items-center flex-wrap">
                        <Form.Group className="m-0 mr-3">
                            <Form.Control
                                placeholder="Search: Account No/Email/Name"
                                type="text"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value ? e.target.value : null)}/>
                        </Form.Group>
                        <button className="btn custom-link btn-primary mr-3" onClick={handleRefresh}>
                            <RefreshIcon/> Refresh
                        </button>
                        <Form.Check
                            className="mr-3"
                            type={'checkbox'}
                            label={'Exclude checked in'}
                            id={`defaultchekbox`}
                            onChange={(e)=>handleExcludeCheck(e)}
                        />
                        <Form.Check
                            className="mr-3"
                            type={'checkbox'}
                            label={'Show Others'}
                            id={`default`}
                            onChange={e => handleCheckboxChange(e)}
                        />
                        <h5 className="m-0">Record Count : {nCount}</h5>
                    </div>
                </div>
                <CheckInTable
                    checkinData={checkinData}
                    isShowOthers={isShowOthers}
                    isLoadingEmploye={isLoadingEmploye}
                />
            </div>
            <HandleConfirmationModel
                title={'Confirmation'}
                question={'Are you sure you want to assign users?'}
                IsOpen={assignEmployeModel}
                handleClose={AssignEmployeModel}
                handleConfirm={assignEmployeApi}
            />
        </div>
    );
};

export default DailyReporting;

import React, { useEffect, useState } from 'react';
import Header from '../../component/header/Header'
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Logout from '../../../components/logout/Logout';

const PreviewTerms = () => {
    let previewPage;
    let theHeader = localStorage.getItem("theHeaderEditor")
    let thetermText = localStorage.getItem("thetermText")
    let termsRowID = localStorage.getItem("termsRowID")
    let editTerms = localStorage.getItem("editbtn")
    const [termsGet, setTermsGet] = useState([])

    // get termofuse Data
    function getTermsQuestion() {
        axios.get(`${process.env.REACT_APP_SERVICE_PLAN_API}termofuse`)
            .then(function (response) {
                if (editTerms == 'true') {
                    response.data.forEach(element => {
                        if (element.id == termsRowID) {
                            element.theheader = theHeader;
                            element.thetext = thetermText;
                        }
                    });
                }
                setTermsGet(response.data)
            })
    }
    useEffect(() => {
        getTermsQuestion()
    }, [])

    return (
        <section className="preview-page-all common-font-section">
            <Header title="Terms Of Use Preview" />
            <div className="faq-main-section">
                <Row className="mx-auto  mobile-button back-logout-btn pt-2">
                    <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
                        <Link to="/termsOfUseAdmin" className="btn btn-light  pl-3 pr-3 pt-2 pb-2" >Back</Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
                       <Logout/>
                    </Col>
                </Row>
            </div>
            <Container className="bg-white mt-4 p-4">
                {
                    termsGet && termsGet.map((item, i) => (
                        <div className="PreviewTerms-content" key={i}>
                            <h5
                                dangerouslySetInnerHTML={{
                                    __html: item.theheader
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: item.thetext
                                }}
                            />
                        </div>
                    ))
                }
                {
                    editTerms == 'false' ?
                        <div className="PreviewTerms-content">
                            <h5 dangerouslySetInnerHTML={{
                                __html: theHeader
                            }} />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: thetermText
                                }}
                            />
                        </div>
                        : ''
                }

            </Container>
        </section>
    )
}

export default PreviewTerms

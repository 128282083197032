import React from "react";
import {loadStripe} from '@stripe/stripe-js/pure';
import {Elements} from "@stripe/react-stripe-js";
import InjectedCheckoutForm from "./InjectedCheckoutForm";

loadStripe.setLoadParameters({advancedFraudSignals: false});


const SubscribePayment = (props) => {
    const planPrice = props?.planPrice
    const nTimeZoneID = props?.nTimeZoneID
    const nServicePlanID = props.nServicePlanID
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    return (
            <Elements stripe={stripePromise}>
                <InjectedCheckoutForm planPrice={planPrice} nServicePlanID={nServicePlanID}
                                      selectedDays={props.selectedDays} nTimeZoneID={nTimeZoneID}/>
            </Elements>
    );
}
export default SubscribePayment

import React, {useEffect, useRef, useState} from 'react';
import Header from '../../../admin/component/header/Header';
import {Row, Col, Form, Button, Modal,} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import './serviceplan.css';
import $ from "jquery";
import axios from 'axios';
import {Link} from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import MaintainenceLogout from '../../../components/logout/MaintainenceLogout';
import {makeStyles} from '@material-ui/core/styles';
import SessionExpiredAdmin from '../../../components/SessionExpired/SessionExpiredAdmin'
import Back from '../../../assets/images/Back_white.png'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

let filterValues2 = []
const ServicePlan = () => {
    const [servicePlan, setServicePlan] = useState("")
    const [monthlyRate, setMonthlyRate] = useState("")
    const [yearlyRate, setYearlyRate] = useState("")
    const [note, setNote] = useState("")
    const [nisActive, setNisActive] = useState(2)
    const textInput = useRef(null);
    const [deletebtnDisable, setDeletebtnDisable] = useState(false)
    const [serviceGetData, setServiceGetData] = useState([])
    const [edit, setEdit] = useState(false)
    const [sNID, setSNID] = useState("");
    const [sortOrder, setSortOrder] = useState('')
    const [TokenExpired, setTokenExpired] = useState(false);
    const [isSaved, setIsSaved] = useState(false)
    const [isDeleted, setisDeleted] = useState(false);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    // get service data
    function getServiceplan() {
        const data = {
            nALL: 0
        };
        var token = localStorage.getItem('maintenance-token');
        setOpen(true)
        axios.post(process.env.REACT_APP_SERVICE_PLAN_API + 'service/getall', data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then(res => {
            if (res.data.status) {
                setTokenExpired(true)
            } else {
                setIsSaved(false)
                setServiceGetData(res.data)
                setOpen(false)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getServiceplan()
    }, [])
    // table th colums
    const columns = [{
        dataField: 'name',
        text: 'Service Plan',
        style: (cell, row, rowIndex, colIndex) => {
            if (row.id === sNID) {
                return {
                    backgroundColor: '#F0F8FF'
                };
            }
        },
        sort: true
    }, {
        dataField: 'monthlyrate',
        text: 'Monthly Rate',
        formatter: (cell) => {
            let dateObj = cell.trim();
            return `$${dateObj}`;
        },
        sortValue: (cell) => {
            let cellFormat = cell.trim();
            cellFormat = cellFormat.replace('$', '')
            return cellFormat;
        },
        style: (cell, row, rowIndex, colIndex) => {
            if (row.id === sNID) {
                return {
                    backgroundColor: '#F0F8FF'
                };
            }
        },
        sort: true
    }, {
        dataField: 'yearlyrate',
        text: 'Yearly Rate',
        formatter: (cell) => {
            let dateObj = cell.trim();
            return `$${dateObj}`;
        },
        sortValue: (cell) => {
            let cellFormat = cell.trim();
            cellFormat = cellFormat.replace('$', '')
            return cellFormat;
        },
        style: (cell, row, rowIndex, colIndex) => {
            if (row.id === sNID) {
                return {
                    backgroundColor: '#F0F8FF'
                };
            }
        },
        sort: true
    }, {
        dataField: 'statusid',
        text: 'Status',
        formatter: (cell) => {
            if (cell == "1") {
                return (
                    <span>
                        <strong style={{color: '#28a745'}}> Active </strong>
                    </span>
                );
            } else {
                return (
                    <span>
                        <strong style={{color: '#dc3545'}}> In Active </strong>
                    </span>
                );
            }
        },
        style: (cell, row) => {
            if (row.id === sNID) {
                return {
                    backgroundColor: '#F0F8FF'
                };
            }
        },
        sort: true
    }, {
        dataField: 'created',
        text: 'Created',
        formatter: (cell) => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
                dateObj = new Date(cell);
            }
            return cell;
        },
        sortValue: (cell) => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
                dateObj = new Date(cell).getTime();
            }
            return dateObj;
        },
        style: (cell, row, rowIndex, colIndex) => {
            if (row.id === sNID) {
                return {
                    backgroundColor: '#F0F8FF'
                };
            }
        },
        sort: true
    }, {
        dataField: 'updated',
        text: 'Updated',
        formatter: (cell) => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
                dateObj = new Date(cell);
            }
            return cell;
        },
        style: (cell, row, rowIndex, colIndex) => {
            if (row.id === sNID) {
                return {
                    backgroundColor: '#F0F8FF'
                };
            }
        },
        sortValue: (cell) => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
                dateObj = new Date(cell).getTime();
            }
            return dateObj;
        },
        sort: true
    }
    ];
    // table select row
    const rowEvents = {
        onClick: (e, row) => {
            getServiceplan()
            const cdata = serviceGetData.find(ele => ele.id == row.id);
            setSNID(cdata.id)
            setServicePlan(cdata.name)
            setNote(cdata.description)
            setMonthlyRate(cdata.monthlyrate)
            setYearlyRate(cdata.yearlyrate)
            setNisActive(cdata.statusid)
            setSortOrder(cdata.sortorder)
            setEdit(true);
            setDeletebtnDisable(true)
            if (row.statusid === 1) {
                $(`.Checked-box`).prop("checked", true)
            } else {
                $(`.Checked-box`).prop("checked", false)
            }
        }
    }

    // new Add Btn
    const newButtonClick = () => {
        setServicePlan('');
        setMonthlyRate('');
        setYearlyRate('');
        setNote('');
        setSortOrder('')
        setEdit(false);
        $(`.Checked-box`).prop("checked", false)
        textInput.current.focus();
        setDeletebtnDisable(false)
    }
    // checkbox
    const checkboxFun = (e) => {
        let target = e.target;
        if (target.checked) {
            setNisActive(1)
        } else {
            setNisActive(2)
        }
    }
    // delete records
    const deleteButtonClicked = () => {
        const data = {
            nID: sNID,
        };
        var token = localStorage.getItem('maintenance-token');
        axios.post(process.env.REACT_APP_SERVICE_PLAN_API + 'service/delete', data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then(res => {
            if (res.data.status) {
                setTokenExpired(true)
            }else {
                setServicePlan('');
                setMonthlyRate('');
                setYearlyRate('');
                setNote('');
                setSortOrder('')
                $(`.Checked-box`).prop("checked", false)
                textInput.current.focus();
                setDeletebtnDisable(false)
                getServiceplan()
                handleCloseDailog()
            }
        }).catch(err => {
            console.log(err);
        });
    }
    // submite form
    const formSubmitHandler = (e) => {
        setIsSaved(true)
        e.preventDefault()
        if (!edit) {
            const data = {
                sName: servicePlan,
                sDescription: note,
                nMonthlyRate: monthlyRate,
                nYearlyRate: yearlyRate,
                nIsActive: nisActive,
                statusid: nisActive,
                nSortOrder: sortOrder,
                nID: ''
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(process.env.REACT_APP_SERVICE_PLAN_API + 'service/create', data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setIsSaved(false)
                    setServicePlan('');
                    setMonthlyRate('');
                    setYearlyRate('');
                    setNote('');
                    setEdit(false);
                    setSortOrder('')
                    $(`.Checked-box`).prop("checked", false)
                    textInput.current.focus();
                    setDeletebtnDisable(false)
                    getServiceplan()
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            const data = {
                sName: servicePlan,
                sDescription: note,
                nMonthlyRate: monthlyRate,
                nYearlyRate: yearlyRate,
                nIsActive: nisActive,
                nSortOrder: sortOrder,
                statusid: nisActive,
                nID: sNID
            }
            var token = localStorage.getItem('maintenance-token');
            axios.post(process.env.REACT_APP_SERVICE_PLAN_API + 'service/create', data,
                {headers: {Authorization: `Bearer ${token}`}}
            ).then(res => {
                if (res.data.status) {
                    setTokenExpired(true)
                }else {
                    setIsSaved(false)
                    setServicePlan('');
                    setMonthlyRate('');
                    setYearlyRate('');
                    setNote('');
                    setSortOrder('')
                    setEdit(false);
                    $(`.Checked-box`).prop("checked", false)
                    textInput.current.focus();
                    setDeletebtnDisable(false)
                    getServiceplan()
                }
            }).catch(err => {
                console.log(err);
                setIsSaved(false)
            });
        }
        getServiceplan()
    }

    // active and inactive
    function AllactiveInActiveData(e) {
        let target = e.target;
        let value = target.value;
        if (target.checked) {
            if (value === "all") {
                serviceGetData.forEach(element => {
                    filterValues2.push(element.statusid)
                });
            }
        } else {
            serviceGetData.forEach(element => {
                filterValues2 = filterValues2.filter(item => item !== element.statusid)
            });
        }

    }

    function changeRadiobtn(e) {
        let target = e.target;
        let value = target.value;
        if (target.checked) {
            if (value === "all") {
            } else if (value === "active") {
            } else if (value === "InActive") {
            }
        } else {
        }
    }

    const handleOpenDailog = ()=>{
        setisDeleted(true)
    }

    const handleCloseDailog = () =>{
        setisDeleted(false)
    }

    return (
        <>
            {
                TokenExpired
                && <SessionExpiredAdmin/>
            }
            <Modal show={isDeleted} onHide={handleCloseDailog}>
                <Modal.Header style={{backgroundColor: '#7030a0', color: 'white'}}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete the selected record?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDailog}>
                        No
                    </Button>
                    <Button style={{backgroundColor: '#7030a0'}} onClick={deleteButtonClicked}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
            <section className="service-plan-section common-font-section">
                <Header title="Service Plans"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maintenance" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <MaintainenceLogout/>
                    </Col>
                </Row>
                <div className="service-content">
                    <div className="divider-mobile"></div>
                    <Row className="mx-auto text-center pt-4 mobile-direction content-table-form">
                        <Col lg={6} md={12} className="p-2 right-side-table " id="style-2">
                            <div class="form-check checkbox-all-check">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio1" value="all" onChange={(e) => changeRadiobtn(e)}/>
                                    <label class="form-check-label" for="inlineRadio1">All</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio2" value="active" onChange={(e) => changeRadiobtn(e)}/>
                                    <label class="form-check-label" for="inlineRadio2">Active</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                           id="inlineRadio3" value="InActive" onChange={(e) => changeRadiobtn(e)}/>
                                    <label class="form-check-label" for="inlineRadio3">InActive </label>
                                </div>
                            </div>
                            <BootstrapTable keyField='id' hover condensed data={serviceGetData && serviceGetData}
                                            columns={columns} rowEvents={rowEvents}/>
                        </Col>
                        <div className="divider-mobile"></div>
                        <Col lg={6} md={12} className="p-2">
                            <div className="Service-plan-addsection">
                                <div className="add-button-section">
                                    <Button className="btn btn-success" onClick={newButtonClick}>Add New Item</Button>
                                    {
                                        deletebtnDisable ?
                                            <Button variant="danger" onClick={handleOpenDailog}>Delete</Button>
                                            :
                                            <Button className="btn btn-danger disable-btn-delete"
                                                    disabled>Delete</Button>
                                    }
                                </div>
                                <Form onSubmit={(e) => formSubmitHandler(e)}>
                                    <div className="d-flex align-items-center mt-3">
                                        <Form.Group controlId="formBasicCheckbox" className="w-50 ">
                                            <Form.Label className="checkbox-active-lable">
                                                Status:
                                            </Form.Label>
                                            <input type="checkbox" onChange={e => checkboxFun(e)}
                                                   className="Checked-box mr-2"/>
                                            <Form.Label>Is Active</Form.Label>
                                        </Form.Group>
                                        <Form.Group className="d-flex w-50 justify-content-end sort-sec mr-5 pr-4"
                                                    controlId="formBasicCheckbox">
                                            <Form.Label className="sort-order-lable">
                                                Sort Order:
                                            </Form.Label>
                                            <Form.Control type="number" value={sortOrder} className="sort-order-input"
                                                          onChange={(e) => setSortOrder(e.target.value)}/>
                                        </Form.Group>
                                    </div>

                                    <Form.Group as={Row} controlId="formPlaintextService">
                                        <Form.Label column sm="2" md="2">
                                            Service Plan:
                                        </Form.Label>
                                        <Col sm="10" md="9" xs="12">
                                            <Form.Control type="text" ref={textInput} value={servicePlan}
                                                          onChange={(e) => setServicePlan(e.target.value)}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextMonthly"
                                                className='row-input-changer Monthly-yerly-section'>
                                        <Col>
                                            <Row>
                                                <Col lg='4'>
                                                    <Form.Label>
                                                        Monthly Rate:
                                                    </Form.Label>
                                                </Col>
                                                <Col className="service-data-columm  monthly-input-type">
                                                    <Form.Control type="number" value={monthlyRate}
                                                                  onChange={(e) => setMonthlyRate(e.target.value)}/>
                                                    <span>$</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col lg='3'>
                                                    <Form.Label>
                                                        Yearly Rate:
                                                    </Form.Label>
                                                </Col>
                                                <Col className="service-data-columm Yearly-input-type">
                                                    <Form.Control type="number" value={yearlyRate}
                                                                  onChange={(e) => setYearlyRate(e.target.value)}/>
                                                    <span>$</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Note:</Form.Label>
                                        <Form.Control as="textarea" value={note} rows={10}
                                                      onChange={(e) => setNote(e.target.value)}/>
                                    </Form.Group>
                                    <Button className="submite-button " type="submit" style={{height: '45px'}}>
                                        Save
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
export default ServicePlan

import React from 'react';
import {Button, Modal} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import './user-info-model.css'

const UserInformationModel = (props) => {
    const {title,tabDetailModel,handleTabDetail,data,column,size,className} = props;
    return (
        <Modal
            centered
            show={tabDetailModel}
            onHide={handleTabDetail}
            backdrop="static"
            keyboard={false}
            size={size}
            className={`assign-confirmation-modal ${className}`}
        >
            <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}} closeButton>
                <Modal.Title className={'text-capitalize'}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={className}>
                <BootstrapTable
                    keyField='id'
                    rowClasses="custom-info-table" hover
                    data={data}
                    columns={column}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleTabDetail}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserInformationModel;
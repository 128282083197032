import React from 'react';
import {Button, Modal} from "react-bootstrap";

const ConfirmationModel = (props) => {
    const {IsOpen,handleClose,handlePhoneCall,getUserDetailApi} = props;

    const handleMakePhoneCall = () => {
        handlePhoneCall().then(() => {
            getUserDetailApi();
        });
    };

    return (
        <Modal
            centered
            show={IsOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="assign-confirmation-modal"
        >
            <Modal.Header style={{ backgroundColor: "#7030a0", color: "white" }} closeButton>
                <Modal.Title>Phone Call</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure want to sent phone call ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" className="" onClick={handleMakePhoneCall}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModel;
import React, {useState} from 'react'
import {Col, Container, Form, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import Logout from '../../../components/logout/Logout';
import Header from '../../component/header/Header';
import './account.css';

const AccountPauseActivated = (props) => {
    let reporting = props.location.backBtnProps
    console.log(reporting)
    return (
        <section className="activated-pause-section common-font-section">
            <Header title="Pause/Activate Account"/>
            <div className="faq-main-section">
                <Row className="mx-auto  mobile-button back-logout-btn pt-2">
                    <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-start">
                        <Link to={reporting ? '/reporting' : "/maindashboard"}
                              className="btn btn-light  pl-3 pr-3 pt-2 pb-2">Back</Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="p-2 text-right">
                        <Logout/>
                    </Col>
                </Row>
            </div>
            <Container className="activated-pause-content">
                <Form>
                    <Form.Group>
                        {/* <Col><InputGroup.Radio aria-label="Radio button for following text input" /></Col> */}
                        <div className="d-flex justify-content-between align-items-center activated-push-account">
                            <div class="form-check radio-btn">
                                <label class="form-check-label mr-5" for="flexRadioDefault1">
                                    Pause My Account:
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                       id="flexRadioDefault1"/>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label mr-5" for="flexRadioDefault2">
                                    Re Activate My Account:
                                </label>
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                       id="flexRadioDefault2"/>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center activated-input-div">
                        <Form.Label> For How Many Days?</Form.Label>
                        <Form.Control type="number"/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Reason:</Form.Label>
                        <Form.Control as="textarea" rows={15}/>
                    </Form.Group>
                    <Button className="submite-button" type="submit">
                        Save
                    </Button>
                </Form>
            </Container>
        </section>
    )
}

export default AccountPauseActivated
